const Options = [
  {
    id: 100100,
    label: "달팽이",
    type: "mob",
    extra: [],
  },
  {
    id: 100101,
    label: "파란 달팽이",
    type: "mob",
    extra: [],
  },
  {
    id: 1110100,
    label: "초록버섯",
    type: "mob",
    extra: [],
  },
  {
    id: 1110101,
    label: "다크 스텀프",
    type: "mob",
    extra: [],
  },
  {
    id: 1120100,
    label: "옥토퍼스",
    type: "mob",
    extra: [],
  },
  {
    id: 1130100,
    label: "엑스텀프",
    type: "mob",
    extra: [],
  },
  {
    id: 1140100,
    label: "고스텀프",
    type: "mob",
    extra: [],
  },
  {
    id: 120100,
    label: "스포아",
    type: "mob",
    extra: [],
  },
  {
    id: 1210100,
    label: "돼지",
    type: "mob",
    extra: [],
  },
  {
    id: 1210101,
    label: "리본 돼지",
    type: "mob",
    extra: [],
  },
  {
    id: 1210102,
    label: "주황버섯",
    type: "mob",
    extra: [],
  },
  {
    id: 1210103,
    label: "버블링",
    type: "mob",
    extra: [],
  },
  {
    id: 130100,
    label: "스텀프",
    type: "mob",
    extra: [],
  },
  {
    id: 130101,
    label: "빨간 달팽이",
    type: "mob",
    extra: [],
  },
  {
    id: 210100,
    label: "슬라임",
    type: "mob",
    extra: [],
  },
  {
    id: 2110200,
    label: "뿔버섯",
    type: "mob",
    extra: [],
  },
  {
    id: 2130100,
    label: "다크 엑스텀프",
    type: "mob",
    extra: [],
  },
  {
    id: 2130103,
    label: "주니어 네키",
    type: "mob",
    extra: [],
  },
  {
    id: 2220100,
    label: "파란버섯",
    type: "mob",
    extra: [],
  },
  {
    id: 2230100,
    label: "이블아이",
    type: "mob",
    extra: [],
  },
  {
    id: 2230101,
    label: "좀비버섯",
    type: "mob",
    extra: [],
  },
  {
    id: 2230102,
    label: "와일드보어",
    type: "mob",
    extra: ["와보"],
  },
  {
    id: 2230103,
    label: "트릭스터",
    type: "mob",
    extra: [],
  },
  {
    id: 2230104,
    label: "그린 트릭스터",
    type: "mob",
    extra: [],
  },
  {
    id: 2230105,
    label: "씨클",
    type: "mob",
    extra: [],
  },
  {
    id: 2230106,
    label: "씨코",
    type: "mob",
    extra: [],
  },
  {
    id: 2230107,
    label: "크라피",
    type: "mob",
    extra: [],
  },
  {
    id: 2230108,
    label: "핀붐",
    type: "mob",
    extra: [],
  },
  {
    id: 2230109,
    label: "버블피쉬",
    type: "mob",
    extra: [],
  },
  {
    id: 2230110,
    label: "우드 마스크",
    type: "mob",
    extra: [],
  },
  {
    id: 2230111,
    label: "스톤 마스크",
    type: "mob",
    extra: [],
  },
  {
    id: 2230200,
    label: "플라워 피쉬",
    type: "mob",
    extra: [],
  },
  {
    id: 2300100,
    label: "스티지",
    type: "mob",
    extra: [],
  },
  {
    id: 3000000,
    label: "스톤볼",
    type: "mob",
    extra: [],
  },
  {
    id: 3000001,
    label: "페어리",
    type: "mob",
    extra: [],
  },
  {
    id: 3000002,
    label: "페어리",
    type: "mob",
    extra: [],
  },
  {
    id: 3000003,
    label: "페어리",
    type: "mob",
    extra: [],
  },
  {
    id: 3000004,
    label: "페어리",
    type: "mob",
    extra: [],
  },
  {
    id: 3000005,
    label: "브라운테니",
    type: "mob",
    extra: [],
  },
  {
    id: 3000006,
    label: "크립",
    type: "mob",
    extra: [],
  },
  {
    id: 3110100,
    label: "리게이터",
    type: "mob",
    extra: [],
  },
  {
    id: 3110101,
    label: "핑크테니",
    type: "mob",
    extra: [],
  },
  {
    id: 3110102,
    label: "라츠",
    type: "mob",
    extra: [],
  },
  {
    id: 3210100,
    label: "파이어보어",
    type: "mob",
    extra: ["파보"],
  },
  {
    id: 3210200,
    label: "주니어 샐리온",
    type: "mob",
    extra: [],
  },
  {
    id: 3210201,
    label: "주니어 라이오너",
    type: "mob",
    extra: [],
  },
  {
    id: 3210202,
    label: "주니어 그류핀",
    type: "mob",
    extra: [],
  },
  {
    id: 3210203,
    label: "팬더테니",
    type: "mob",
    extra: [],
  },
  {
    id: 3210204,
    label: "더키 패밀리",
    type: "mob",
    extra: [],
  },
  {
    id: 3210205,
    label: "블랙 라츠",
    type: "mob",
    extra: [],
  },
  {
    id: 3210206,
    label: "치크세이버",
    type: "mob",
    extra: [],
  },
  {
    id: 3210207,
    label: "티키",
    type: "mob",
    extra: [],
  },
  {
    id: 3210208,
    label: "레츠",
    type: "mob",
    extra: [],
  },
  {
    id: 3210450,
    label: "스쿠버 페페",
    type: "mob",
    extra: [],
  },
  {
    id: 3210800,
    label: "루팡",
    type: "mob",
    extra: [],
  },
  {
    id: 3230100,
    label: "커즈아이",
    type: "mob",
    extra: [],
  },
  {
    id: 3230101,
    label: "주니어 레이스",
    type: "mob",
    extra: [],
  },
  {
    id: 3230102,
    label: "로랑",
    type: "mob",
    extra: [],
  },
  {
    id: 3230103,
    label: "킹 블록퍼스",
    type: "mob",
    extra: [],
  },
  {
    id: 3230104,
    label: "마스크피쉬",
    type: "mob",
    extra: [],
  },
  {
    id: 3230200,
    label: "스타픽시",
    type: "mob",
    extra: [],
  },
  {
    id: 3230300,
    label: "주니어 부기",
    type: "mob",
    extra: [],
  },
  {
    id: 3230301,
    label: "주니어 부기",
    type: "mob",
    extra: [],
  },
  {
    id: 3230302,
    label: "블록퍼스",
    type: "mob",
    extra: [],
  },
  {
    id: 3230303,
    label: "핑크세이버",
    type: "mob",
    extra: [],
  },
  {
    id: 3230304,
    label: "스카이세이버",
    type: "mob",
    extra: [],
  },
  {
    id: 3230305,
    label: "장난감 목마",
    type: "mob",
    extra: [],
  },
  {
    id: 3230306,
    label: "크로노스",
    type: "mob",
    extra: [],
  },
  {
    id: 3230307,
    label: "처프",
    type: "mob",
    extra: [],
  },
  {
    id: 3230308,
    label: "트위터",
    type: "mob",
    extra: [],
  },
  {
    id: 3230400,
    label: "북치는 토끼",
    type: "mob",
    extra: [],
  },
  {
    id: 3230405,
    label: "주니어 씰",
    type: "mob",
    extra: [],
  },
  {
    id: 4130100,
    label: "카파 드레이크",
    type: "mob",
    extra: [],
  },
  {
    id: 4130101,
    label: "엄티",
    type: "mob",
    extra: [],
  },
  {
    id: 4130102,
    label: "다크 네펜데스",
    type: "mob",
    extra: [],
  },
  {
    id: 4130103,
    label: "롬바드",
    type: "mob",
    extra: [],
  },
  {
    id: 4230100,
    label: "콜드아이",
    type: "mob",
    extra: [],
  },
  {
    id: 4230101,
    label: "좀비루팡",
    type: "mob",
    extra: [],
  },
  {
    id: 4230102,
    label: "레이스",
    type: "mob",
    extra: [],
  },
  {
    id: 4230103,
    label: "아이언 호그",
    type: "mob",
    extra: [],
  },
  {
    id: 4230104,
    label: "클랑",
    type: "mob",
    extra: [],
  },
  {
    id: 4230105,
    label: "네펜데스",
    type: "mob",
    extra: [],
  },
  {
    id: 4230106,
    label: "루나픽시",
    type: "mob",
    extra: [],
  },
  {
    id: 4230107,
    label: "플라이아이",
    type: "mob",
    extra: [],
  },
  {
    id: 4230108,
    label: "[★] 주니어 불독",
    type: "mob",
    extra: [],
  },
  {
    id: 4230109,
    label: "블록골렘",
    type: "mob",
    extra: [],
  },
  {
    id: 4230110,
    label: "킹 블록골렘",
    type: "mob",
    extra: [],
  },
  {
    id: 4230111,
    label: "로보토이",
    type: "mob",
    extra: [],
  },
  {
    id: 4230112,
    label: "마스터 로보",
    type: "mob",
    extra: [],
  },
  {
    id: 4230113,
    label: "틱톡",
    type: "mob",
    extra: [],
  },
  {
    id: 4230114,
    label: "플래툰 크로노스",
    type: "mob",
    extra: [],
  },
  {
    id: 4230115,
    label: "마스터 크로노스",
    type: "mob",
    extra: [],
  },
  {
    id: 4230116,
    label: "바나드 그레이",
    type: "mob",
    extra: [],
  },
  {
    id: 4230117,
    label: "제타 그레이",
    type: "mob",
    extra: [],
  },
  {
    id: 4230118,
    label: "울트라 그레이",
    type: "mob",
    extra: [],
  },
  {
    id: 4230119,
    label: "마티안",
    type: "mob",
    extra: [],
  },
  {
    id: 4230120,
    label: "플라티안",
    type: "mob",
    extra: [],
  },
  {
    id: 4230121,
    label: "메카티안",
    type: "mob",
    extra: [],
  },
  {
    id: 4230123,
    label: "스파커",
    type: "mob",
    extra: [],
  },
  {
    id: 4230124,
    label: "프리져",
    type: "mob",
    extra: [],
  },
  {
    id: 4230125,
    label: "스켈독",
    type: "mob",
    extra: [],
  },
  {
    id: 4230126,
    label: "머미독",
    type: "mob",
    extra: [],
  },
  {
    id: 4230200,
    label: "푸퍼",
    type: "mob",
    extra: [],
  },
  {
    id: 4230201,
    label: "포이즌 푸퍼",
    type: "mob",
    extra: [],
  },
  {
    id: 4230300,
    label: "월묘",
    type: "mob",
    extra: [],
  },
  {
    id: 4230400,
    label: "아이언보어",
    type: "mob",
    extra: [],
  },
  {
    id: 4240000,
    label: "원로 그레이",
    type: "mob",
    extra: [],
  },
  {
    id: 5100000,
    label: "주니어 예티",
    type: "mob",
    extra: [],
  },
  {
    id: 5100002,
    label: "파이어봄",
    type: "mob",
    extra: [],
  },
  {
    id: 5100003,
    label: "호돌이",
    type: "mob",
    extra: [],
  },
  {
    id: 5100004,
    label: "삼미호",
    type: "mob",
    extra: [],
  },
  {
    id: 5100005,
    label: "호걸",
    type: "mob",
    extra: [],
  },
  {
    id: 5120000,
    label: "러스터픽시",
    type: "mob",
    extra: [],
  },
  {
    id: 5120001,
    label: "샐리온",
    type: "mob",
    extra: [],
  },
  {
    id: 5120002,
    label: "라이오너",
    type: "mob",
    extra: [],
  },
  {
    id: 5120003,
    label: "그류핀",
    type: "mob",
    extra: [],
  },
  {
    id: 5120100,
    label: "머신 MT-09",
    type: "mob",
    extra: [],
  },
  {
    id: 5130100,
    label: "드레이크",
    type: "mob",
    extra: [],
  },
  {
    id: 5130101,
    label: "스톤골렘",
    type: "mob",
    extra: [],
  },
  {
    id: 5130102,
    label: "다크 스톤골렘",
    type: "mob",
    extra: [],
  },
  {
    id: 5130103,
    label: "크로코",
    type: "mob",
    extra: [],
  },
  {
    id: 5130104,
    label: "헥터",
    type: "mob",
    extra: [],
  },
  {
    id: 5130105,
    label: "다크 주니어 예티",
    type: "mob",
    extra: [],
  },
  {
    id: 5130107,
    label: "쿨리 좀비",
    type: "mob",
    extra: [],
  },
  {
    id: 5130108,
    label: "[★] 마이너 좀비",
    type: "mob",
    extra: [],
  },
  {
    id: 5140000,
    label: "화이트팽",
    type: "mob",
    extra: [],
  },
  {
    id: 5150000,
    label: "믹스골렘",
    type: "mob",
    extra: ["믹골"],
  },
  {
    id: 5150001,
    label: "스켈레톤 사병",
    type: "mob",
    extra: [],
  },
  {
    id: 5200000,
    label: "주니어 스톤볼",
    type: "mob",
    extra: [],
  },
  {
    id: 5200001,
    label: "아이스 스톤볼",
    type: "mob",
    extra: [],
  },
  {
    id: 5200002,
    label: "파이어 스톤볼",
    type: "mob",
    extra: [],
  },
  {
    id: 5300000,
    label: "리티",
    type: "mob",
    extra: [],
  },
  {
    id: 5300001,
    label: "다크 리티",
    type: "mob",
    extra: [],
  },
  {
    id: 5300100,
    label: "멜러디",
    type: "mob",
    extra: [],
  },
  {
    id: 5400000,
    label: "주니어 페페",
    type: "mob",
    extra: [],
  },
  {
    id: 6130100,
    label: "레드 드레이크",
    type: "mob",
    extra: [],
  },
  {
    id: 6130101,
    label: "머쉬맘",
    type: "mob",
    extra: [],
  },
  {
    id: 6130102,
    label: "페페",
    type: "mob",
    extra: [],
  },
  {
    id: 6130103,
    label: "페페",
    type: "mob",
    extra: [],
  },
  {
    id: 6130200,
    label: "[★] 버피",
    type: "mob",
    extra: [],
  },
  {
    id: 6130202,
    label: "깨비",
    type: "mob",
    extra: [],
  },
  {
    id: 6230100,
    label: "와일드카고",
    type: "mob",
    extra: [],
  },
  {
    id: 6230200,
    label: "다크 페페",
    type: "mob",
    extra: [],
  },
  {
    id: 6230201,
    label: "다크 페페",
    type: "mob",
    extra: [],
  },
  {
    id: 6230300,
    label: "[★] 레이지 버피",
    type: "mob",
    extra: [],
  },
  {
    id: 6230400,
    label: "[★] 소울테니",
    type: "mob",
    extra: [],
  },
  {
    id: 6230500,
    label: "[★] 마스터 소울테니",
    type: "mob",
    extra: [],
  },
  {
    id: 6230600,
    label: "아이스 드레이크",
    type: "mob",
    extra: [],
  },
  {
    id: 6230601,
    label: "다크 드레이크",
    type: "mob",
    extra: [],
  },
  {
    id: 6230602,
    label: "스켈레톤 장교",
    type: "mob",
    extra: [],
  },
  {
    id: 6300000,
    label: "예티",
    type: "mob",
    extra: [],
  },
  {
    id: 6300001,
    label: "예티",
    type: "mob",
    extra: [],
  },
  {
    id: 6300002,
    label: "예티",
    type: "mob",
    extra: [],
  },
  {
    id: 6300005,
    label: "좀비 머쉬맘",
    type: "mob",
    extra: ["좀머"],
  },
  {
    id: 6300100,
    label: "[★] 버푼",
    type: "mob",
    extra: [],
  },
  {
    id: 6400000,
    label: "다크 예티",
    type: "mob",
    extra: [],
  },
  {
    id: 6400001,
    label: "다크 예티",
    type: "mob",
    extra: [],
  },
  {
    id: 6400002,
    label: "다크 예티",
    type: "mob",
    extra: [],
  },
  {
    id: 6400100,
    label: "[★] 딥 버푼",
    type: "mob",
    extra: [],
  },
  {
    id: 7130000,
    label: "루이넬",
    type: "mob",
    extra: [],
  },
  {
    id: 7130001,
    label: "[★] 불독",
    type: "mob",
    extra: [],
  },
  {
    id: 7130010,
    label: "[★] 데스테니",
    type: "mob",
    extra: [],
  },
  {
    id: 7130020,
    label: "망둥이",
    type: "mob",
    extra: [],
  },
  {
    id: 7130100,
    label: "타우로마시스",
    type: "mob",
    extra: [],
  },
  {
    id: 7130101,
    label: "타우로스피어",
    type: "mob",
    extra: [],
  },
  {
    id: 7130103,
    label: "스켈레톤 지휘관",
    type: "mob",
    extra: [],
  },
  {
    id: 7130200,
    label: "웨어울프",
    type: "mob",
    extra: [],
  },
  {
    id: 7130300,
    label: "[★] 마스터 데스테니",
    type: "mob",
    extra: [],
  },
  {
    id: 7130400,
    label: "노란 왕도깨비",
    type: "mob",
    extra: [],
  },
  {
    id: 7130401,
    label: "파란 왕도깨비",
    type: "mob",
    extra: [],
  },
  {
    id: 7130402,
    label: "초록 왕도깨비",
    type: "mob",
    extra: [],
  },
  {
    id: 7140000,
    label: "[★] 파이렛",
    type: "mob",
    extra: [],
  },
  {
    id: 7160000,
    label: "[★] 듀얼 파이렛",
    type: "mob",
    extra: [],
  },
  {
    id: 8130100,
    label: "주니어 발록",
    type: "mob",
    extra: [],
  },
  {
    id: 8140000,
    label: "라이칸스로프",
    type: "mob",
    extra: [],
  },
  {
    id: 8140200,
    label: "[★] 클라크",
    type: "mob",
    extra: [],
  },
  {
    id: 8140300,
    label: "[★] 다크 클라크",
    type: "mob",
    extra: [],
  },
  {
    id: 8140500,
    label: "[★] 파이어독",
    type: "mob",
    extra: [],
  },
  {
    id: 8140600,
    label: "본피쉬",
    type: "mob",
    extra: [],
  },
  {
    id: 8141000,
    label: "[★] 바이킹",
    type: "mob",
    extra: [],
  },
  {
    id: 8141100,
    label: "[★] 기간틱 바이킹",
    type: "mob",
    extra: [],
  },
  {
    id: 8141300,
    label: "스퀴드",
    type: "mob",
    extra: [],
  },
  {
    id: 8142000,
    label: "[★] 팬텀워치",
    type: "mob",
    extra: [],
  },
  {
    id: 8142100,
    label: "리셀스퀴드",
    type: "mob",
    extra: [],
  },
  {
    id: 8143000,
    label: "[★] G.팬텀워치",
    type: "mob",
    extra: [],
  },
  {
    id: 8150000,
    label: "크림슨 발록",
    type: "mob",
    extra: [],
  },
  {
    id: 8150100,
    label: "샤크",
    type: "mob",
    extra: [],
  },
  {
    id: 8150101,
    label: "콜드샤크",
    type: "mob",
    extra: [],
  },
  {
    id: 8160000,
    label: "[★] 게이트키퍼",
    type: "mob",
    extra: [],
  },
  {
    id: 8170000,
    label: "[★] 타나토스",
    type: "mob",
    extra: [],
  },
  {
    id: 8500002,
    label: "파풀라투스",
    type: "mob",
    extra: [],
  },
  {
    id: 8510000,
    label: "피아누스",
    type: "mob",
    extra: [],
  },
  {
    id: 8800002,
    label: "자쿰",
    type: "mob",
    extra: [],
  },
  {
    id: 1032000,
    label: "추 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032001,
    label: "한쪽 은 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032002,
    label: "사파이어 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032003,
    label: "자수정 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032004,
    label: "금 링 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032005,
    label: "레드 크로스링",
    type: "item",
    extra: [],
  },
  {
    id: 1032006,
    label: "번개 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032007,
    label: "에메랄드 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032008,
    label: "캣츠 아이",
    type: "item",
    extra: [],
  },
  {
    id: 1032009,
    label: "옐로우 스퀘어",
    type: "item",
    extra: [],
  },
  {
    id: 1032010,
    label: "별 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032011,
    label: "블루 문",
    type: "item",
    extra: [],
  },
  {
    id: 1032012,
    label: "해골 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032013,
    label: "빨간하트 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032014,
    label: "분홍꽃 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032015,
    label: "메탈실버 이어링",
    type: "item",
    extra: [],
  },
  {
    id: 1032016,
    label: "메탈하트 이어링",
    type: "item",
    extra: [],
  },
  {
    id: 1032017,
    label: "장미꽃 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1032018,
    label: "펜지 이어링",
    type: "item",
    extra: [],
  },
  {
    id: 1032019,
    label: "크리스탈 플라워링",
    type: "item",
    extra: [],
  },
  {
    id: 1032020,
    label: "골드드롭 이어링",
    type: "item",
    extra: [],
  },
  {
    id: 1032021,
    label: "홀리 크로스링",
    type: "item",
    extra: [],
  },
  {
    id: 1032022,
    label: "하프 이어링",
    type: "item",
    extra: [],
  },
  {
    id: 1032023,
    label: "딸기 귀고리",
    type: "item",
    extra: [],
  },
  {
    id: 1002004,
    label: "브라운 그레이트 헬멧",
    type: "item",
    extra: [],
  },
  {
    id: 1002005,
    label: "아이언 버거넷 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002008,
    label: "갈색 가죽 모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002013,
    label: "손오공 금 머리띠",
    type: "item",
    extra: [],
  },
  {
    id: 1002019,
    label: "흰색 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002020,
    label: "빨간색 별 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002021,
    label: "스틸 노르만 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002022,
    label: "낡은 스틸 노르만 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002023,
    label: "주스팅",
    type: "item",
    extra: [],
  },
  {
    id: 1002024,
    label: "에메랄드 돔",
    type: "item",
    extra: [],
  },
  {
    id: 1002025,
    label: "레드 듀크",
    type: "item",
    extra: [],
  },
  {
    id: 1002027,
    label: "스틸 샤프 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002028,
    label: "실버 크루세이더 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002029,
    label: "레드 오리엔트 헬멧",
    type: "item",
    extra: [],
  },
  {
    id: 1002030,
    label: "실버 플레닛",
    type: "item",
    extra: [],
  },
  {
    id: 1002033,
    label: "군밤장수 모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002034,
    label: "블루 핀터",
    type: "item",
    extra: [],
  },
  {
    id: 1002035,
    label: "핑크 핀터",
    type: "item",
    extra: [],
  },
  {
    id: 1002036,
    label: "그린 핀터",
    type: "item",
    extra: [],
  },
  {
    id: 1002037,
    label: "블랙 핀터",
    type: "item",
    extra: [],
  },
  {
    id: 1002038,
    label: "브라운 핀터",
    type: "item",
    extra: [],
  },
  {
    id: 1002039,
    label: "브론즈 캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002043,
    label: "브론즈 코이프",
    type: "item",
    extra: [],
  },
  {
    id: 1002045,
    label: "블루 본 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002047,
    label: "레드 그레이트 헬멧",
    type: "item",
    extra: [],
  },
  {
    id: 1002048,
    label: "블루 그레이트 헬멧",
    type: "item",
    extra: [],
  },
  {
    id: 1002054,
    label: "붉은색 가죽 모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002058,
    label: "미스릴 바이킹 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002064,
    label: "손오공 청동 머리띠",
    type: "item",
    extra: [],
  },
  {
    id: 1002065,
    label: "손오공 강철 머리띠",
    type: "item",
    extra: [],
  },
  {
    id: 1002067,
    label: "연두색 머리띠",
    type: "item",
    extra: [],
  },
  {
    id: 1002075,
    label: "붉은색 낡은 고깔 모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002080,
    label: "빨간색 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002081,
    label: "파란색 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002082,
    label: "노란색 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002083,
    label: "검은색 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002084,
    label: "블루 오리엔트 헬멧",
    type: "item",
    extra: [],
  },
  {
    id: 1002085,
    label: "미스릴 크루세이더 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002086,
    label: "브론즈 크루세이더 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002092,
    label: "옐로우 듀크",
    type: "item",
    extra: [],
  },
  {
    id: 1002093,
    label: "블루 듀크",
    type: "item",
    extra: [],
  },
  {
    id: 1002094,
    label: "브론즈 플레닛",
    type: "item",
    extra: [],
  },
  {
    id: 1002095,
    label: "미스릴 플레닛",
    type: "item",
    extra: [],
  },
  {
    id: 1002097,
    label: "노란색 별 두건",
    type: "item",
    extra: [],
  },
  {
    id: 1002098,
    label: "골드 노르만 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002099,
    label: "미스릴 노르만 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002100,
    label: "낡은 브론즈 노르만 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002101,
    label: "낡은 미스릴 노르만 헬름",
    type: "item",
    extra: [],
  },
  {
    id: 1002103,
    label: "핑크문 고깔모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002107,
    label: "레드 시프 후드",
    type: "item",
    extra: [],
  },
  {
    id: 1002113,
    label: "블루 페더햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002118,
    label: "블루 페더윙햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002119,
    label: "그린 페더윙햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002121,
    label: "브라운 페더윙햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002127,
    label: "레드 루즈캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002129,
    label: "브라운 루즈캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002131,
    label: "그린 루즈캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002135,
    label: "브라운 폴레패더햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002136,
    label: "다크 폴레패더햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002137,
    label: "그린 폴레패더햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002138,
    label: "블루 폴레패더햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002139,
    label: "레드 폴레패더햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002141,
    label: "레드 매티",
    type: "item",
    extra: [],
  },
  {
    id: 1002142,
    label: "블루 매티",
    type: "item",
    extra: [],
  },
  {
    id: 1002143,
    label: "그린 매티",
    type: "item",
    extra: [],
  },
  {
    id: 1002144,
    label: "브라운 매티",
    type: "item",
    extra: [],
  },
  {
    id: 1002145,
    label: "다크 매티",
    type: "item",
    extra: [],
  },
  {
    id: 1002146,
    label: "레드 티베리안",
    type: "item",
    extra: [],
  },
  {
    id: 1002151,
    label: "브라운 길티언",
    type: "item",
    extra: [],
  },
  {
    id: 1002152,
    label: "블루 길티언",
    type: "item",
    extra: [],
  },
  {
    id: 1002153,
    label: "레드 길티언",
    type: "item",
    extra: [],
  },
  {
    id: 1002154,
    label: "다크 길티언",
    type: "item",
    extra: [],
  },
  {
    id: 1002155,
    label: "화이트 길티언",
    type: "item",
    extra: [],
  },
  {
    id: 1002158,
    label: "그린 헌트",
    type: "item",
    extra: [],
  },
  {
    id: 1002160,
    label: "브라운 헌트",
    type: "item",
    extra: [],
  },
  {
    id: 1002162,
    label: "블루 네페르",
    type: "item",
    extra: [],
  },
  {
    id: 1002163,
    label: "그린 네페르",
    type: "item",
    extra: [],
  },
  {
    id: 1002164,
    label: "브라운 네페르",
    type: "item",
    extra: [],
  },
  {
    id: 1002165,
    label: "다크 네페르",
    type: "item",
    extra: [],
  },
  {
    id: 1002166,
    label: "레드 크라페",
    type: "item",
    extra: [],
  },
  {
    id: 1002167,
    label: "블루 크라페",
    type: "item",
    extra: [],
  },
  {
    id: 1002168,
    label: "그린 크라페",
    type: "item",
    extra: [],
  },
  {
    id: 1002169,
    label: "브라운 크라페",
    type: "item",
    extra: [],
  },
  {
    id: 1002170,
    label: "다크 크라페",
    type: "item",
    extra: [],
  },
  {
    id: 1002172,
    label: "블루 가이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1002173,
    label: "그린 가이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1002174,
    label: "브라운 가이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1002175,
    label: "다크 가이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1002176,
    label: "레드 버글러",
    type: "item",
    extra: [],
  },
  {
    id: 1002177,
    label: "블루 버글러",
    type: "item",
    extra: [],
  },
  {
    id: 1002178,
    label: "그린 버글러",
    type: "item",
    extra: [],
  },
  {
    id: 1002179,
    label: "브라운 버글러",
    type: "item",
    extra: [],
  },
  {
    id: 1002180,
    label: "다크 버글러",
    type: "item",
    extra: [],
  },
  {
    id: 1002181,
    label: "레드 필퍼",
    type: "item",
    extra: [],
  },
  {
    id: 1002182,
    label: "블루 필퍼",
    type: "item",
    extra: [],
  },
  {
    id: 1002183,
    label: "그린 필퍼",
    type: "item",
    extra: [],
  },
  {
    id: 1002184,
    label: "브라운 필퍼",
    type: "item",
    extra: [],
  },
  {
    id: 1002185,
    label: "다크 필퍼",
    type: "item",
    extra: [],
  },
  {
    id: 1002207,
    label: "적 흑일모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002208,
    label: "청 흑일모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002209,
    label: "녹 흑일모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002210,
    label: "황 흑일모자",
    type: "item",
    extra: [],
  },
  {
    id: 1002211,
    label: "청마로",
    type: "item",
    extra: [],
  },
  {
    id: 1002212,
    label: "적마로",
    type: "item",
    extra: [],
  },
  {
    id: 1002213,
    label: "녹마로",
    type: "item",
    extra: [],
  },
  {
    id: 1002214,
    label: "흑마로",
    type: "item",
    extra: [],
  },
  {
    id: 1002215,
    label: "플레임 골든서클릿",
    type: "item",
    extra: [],
  },
  {
    id: 1002216,
    label: "아쿠아 골든서클릿",
    type: "item",
    extra: [],
  },
  {
    id: 1002217,
    label: "오렌지 골든서클릿",
    type: "item",
    extra: [],
  },
  {
    id: 1002218,
    label: "다크 골든서클릿",
    type: "item",
    extra: [],
  },
  {
    id: 1002242,
    label: "레드 세라피스",
    type: "item",
    extra: [],
  },
  {
    id: 1002243,
    label: "블루 세라피스",
    type: "item",
    extra: [],
  },
  {
    id: 1002244,
    label: "그린 세라피스",
    type: "item",
    extra: [],
  },
  {
    id: 1002245,
    label: "화이트 세라피스",
    type: "item",
    extra: [],
  },
  {
    id: 1002246,
    label: "다크 세라피스",
    type: "item",
    extra: [],
  },
  {
    id: 1002247,
    label: "브론즈 하이드후드",
    type: "item",
    extra: [],
  },
  {
    id: 1002248,
    label: "실버 하이드후드",
    type: "item",
    extra: [],
  },
  {
    id: 1002249,
    label: "다크 하이드후드",
    type: "item",
    extra: [],
  },
  {
    id: 1002252,
    label: "레드 키튼서클렛",
    type: "item",
    extra: [],
  },
  {
    id: 1002253,
    label: "블루 키튼서클렛",
    type: "item",
    extra: [],
  },
  {
    id: 1002254,
    label: "다크 키튼서클렛",
    type: "item",
    extra: [],
  },
  {
    id: 1002267,
    label: "적궁우",
    type: "item",
    extra: [],
  },
  {
    id: 1002268,
    label: "토궁우",
    type: "item",
    extra: [],
  },
  {
    id: 1002269,
    label: "백궁우",
    type: "item",
    extra: [],
  },
  {
    id: 1002270,
    label: "흑궁우",
    type: "item",
    extra: [],
  },
  {
    id: 1002271,
    label: "그린 크로스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002272,
    label: "블루 크로스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002273,
    label: "퍼플 크로스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002274,
    label: "다크 크로스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002275,
    label: "블루 골드윙캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002276,
    label: "레드 골드윙캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002277,
    label: "그린 골드윙캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002278,
    label: "다크 골드윙캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002281,
    label: "브라운 루크",
    type: "item",
    extra: [],
  },
  {
    id: 1002282,
    label: "블루 루크",
    type: "item",
    extra: [],
  },
  {
    id: 1002283,
    label: "퍼플 루크",
    type: "item",
    extra: [],
  },
  {
    id: 1002284,
    label: "화이트 루크",
    type: "item",
    extra: [],
  },
  {
    id: 1002285,
    label: "블러드 루크",
    type: "item",
    extra: [],
  },
  {
    id: 1002286,
    label: "블루 웨어캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002287,
    label: "베이지 웨어캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002288,
    label: "그린 웨어캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002289,
    label: "다크 웨어캡",
    type: "item",
    extra: [],
  },
  {
    id: 1002323,
    label: "그린 쉐이드햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002324,
    label: "브라운 쉐이드햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002325,
    label: "퍼플 쉐이드햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002326,
    label: "레드 쉐이드햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002327,
    label: "브라운 피레타햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002328,
    label: "그린 피레타햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002329,
    label: "레드 피레타햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002330,
    label: "다크 피레타햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002338,
    label: "레드 드래곤 바부타",
    type: "item",
    extra: [],
  },
  {
    id: 1002339,
    label: "블루 드래곤 바부타",
    type: "item",
    extra: [],
  },
  {
    id: 1002340,
    label: "다크 드래곤 바부타",
    type: "item",
    extra: [],
  },
  {
    id: 1002357,
    label: "자쿰의 투구",
    type: "item",
    extra: ["자투"],
  },
  {
    id: 1002365,
    label: "적견랑건",
    type: "item",
    extra: [],
  },
  {
    id: 1002366,
    label: "흑견랑건",
    type: "item",
    extra: [],
  },
  {
    id: 1002402,
    label: "레드 아데스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002403,
    label: "블루 아데스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002404,
    label: "그린 아데스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1002405,
    label: "다크 아데스햇",
    type: "item",
    extra: [],
  },
  {
    id: 1102000,
    label: "초록색 모험가의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102001,
    label: "파란색 모험가의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102002,
    label: "빨간색 모험가의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102003,
    label: "흰색 모험가의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102004,
    label: "검은색 모험가의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102015,
    label: "파란색 마법의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102016,
    label: "빨간색 마법의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102017,
    label: "흰색 마법의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102018,
    label: "검은색 마법의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102021,
    label: "파란색 가이아의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102022,
    label: "빨간색 가이아의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102023,
    label: "흰색 가이아의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102024,
    label: "검은색 가이아의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102026,
    label: "초록색 세라프의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102027,
    label: "파란색 세라프의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102028,
    label: "빨간색 세라프의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102029,
    label: "흰색 세라프의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102030,
    label: "검은색 세라프의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102031,
    label: "초록색 자일즈의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102032,
    label: "자주색 자일즈의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1102035,
    label: "검은색 자일즈의 망토",
    type: "item",
    extra: [],
  },
  {
    id: 1040000,
    label: "황진일갑주",
    type: "item",
    extra: [],
  },
  {
    id: 1040009,
    label: "스틸 코퍼럴",
    type: "item",
    extra: [],
  },
  {
    id: 1040011,
    label: "은색 래더 후드웨어",
    type: "item",
    extra: [],
  },
  {
    id: 1040012,
    label: "블루 서전트",
    type: "item",
    extra: [],
  },
  {
    id: 1040013,
    label: "파란색 원라인 티셔츠",
    type: "item",
    extra: [],
  },
  {
    id: 1040014,
    label: "주황색 스포츠 티셔츠",
    type: "item",
    extra: [],
  },
  {
    id: 1040016,
    label: "오리할콘 마스터 서전트",
    type: "item",
    extra: [],
  },
  {
    id: 1040017,
    label: "회갈색 수련복",
    type: "item",
    extra: [],
  },
  {
    id: 1040019,
    label: "주황색 가람",
    type: "item",
    extra: [],
  },
  {
    id: 1040020,
    label: "푸른색 가람",
    type: "item",
    extra: [],
  },
  {
    id: 1040021,
    label: "지장의",
    type: "item",
    extra: [],
  },
  {
    id: 1040024,
    label: "블루 이너스 체인메일",
    type: "item",
    extra: [],
  },
  {
    id: 1040026,
    label: "지천의",
    type: "item",
    extra: [],
  },
  {
    id: 1040028,
    label: "창천의",
    type: "item",
    extra: [],
  },
  {
    id: 1040029,
    label: "청룡의",
    type: "item",
    extra: [],
  },
  {
    id: 1040030,
    label: "금룡의",
    type: "item",
    extra: [],
  },
  {
    id: 1040034,
    label: "흑야",
    type: "item",
    extra: [],
  },
  {
    id: 1040035,
    label: "청야",
    type: "item",
    extra: [],
  },
  {
    id: 1040037,
    label: "브론즈 코퍼럴",
    type: "item",
    extra: [],
  },
  {
    id: 1040040,
    label: "실버 마스터 서전트",
    type: "item",
    extra: [],
  },
  {
    id: 1040041,
    label: "다크 마스터 서전트",
    type: "item",
    extra: [],
  },
  {
    id: 1040042,
    label: "파란색 파오",
    type: "item",
    extra: [],
  },
  {
    id: 1040043,
    label: "빨간색 파오",
    type: "item",
    extra: [],
  },
  {
    id: 1040044,
    label: "검정색 파오",
    type: "item",
    extra: [],
  },
  {
    id: 1040048,
    label: "브라운 스니크",
    type: "item",
    extra: [],
  },
  {
    id: 1040057,
    label: "다크브라운 스틸러",
    type: "item",
    extra: [],
  },
  {
    id: 1040058,
    label: "다크실버 스틸러",
    type: "item",
    extra: [],
  },
  {
    id: 1040059,
    label: "레드골드 스틸러",
    type: "item",
    extra: [],
  },
  {
    id: 1040060,
    label: "실버블랙 스틸러",
    type: "item",
    extra: [],
  },
  {
    id: 1040061,
    label: "그린 너클베스트",
    type: "item",
    extra: [],
  },
  {
    id: 1040062,
    label: "레드 너클베스트",
    type: "item",
    extra: [],
  },
  {
    id: 1040063,
    label: "블랙 너클베스트",
    type: "item",
    extra: [],
  },
  {
    id: 1040069,
    label: "레드 헌터스 아머",
    type: "item",
    extra: [],
  },
  {
    id: 1040070,
    label: "블루 헌터스 아머",
    type: "item",
    extra: [],
  },
  {
    id: 1040072,
    label: "레드 레골러",
    type: "item",
    extra: [],
  },
  {
    id: 1040073,
    label: "블루 레골러",
    type: "item",
    extra: [],
  },
  {
    id: 1040074,
    label: "그린 레골러",
    type: "item",
    extra: [],
  },
  {
    id: 1040075,
    label: "다크 레골러",
    type: "item",
    extra: [],
  },
  {
    id: 1040076,
    label: "브라운 레골러",
    type: "item",
    extra: [],
  },
  {
    id: 1040079,
    label: "브라운 피에뜨",
    type: "item",
    extra: [],
  },
  {
    id: 1040080,
    label: "다크 피에뜨",
    type: "item",
    extra: [],
  },
  {
    id: 1040081,
    label: "화이트 피에뜨",
    type: "item",
    extra: [],
  },
  {
    id: 1040082,
    label: "카키 쉐도우",
    type: "item",
    extra: [],
  },
  {
    id: 1040083,
    label: "마린 쉐도우",
    type: "item",
    extra: [],
  },
  {
    id: 1040084,
    label: "다크 쉐도우",
    type: "item",
    extra: [],
  },
  {
    id: 1040085,
    label: "자진일갑주",
    type: "item",
    extra: [],
  },
  {
    id: 1040086,
    label: "청진일갑주",
    type: "item",
    extra: [],
  },
  {
    id: 1040087,
    label: "블루 숄더메일",
    type: "item",
    extra: [],
  },
  {
    id: 1040088,
    label: "오우커 숄더메일",
    type: "item",
    extra: [],
  },
  {
    id: 1040089,
    label: "엄버 숄더메일",
    type: "item",
    extra: [],
  },
  {
    id: 1040090,
    label: "그린 오리엔타이칸",
    type: "item",
    extra: [],
  },
  {
    id: 1040091,
    label: "레드 오리엔타이칸",
    type: "item",
    extra: [],
  },
  {
    id: 1040092,
    label: "블루 오리엔타이칸",
    type: "item",
    extra: [],
  },
  {
    id: 1040093,
    label: "다크 오리엔타이칸",
    type: "item",
    extra: [],
  },
  {
    id: 1040094,
    label: "적일",
    type: "item",
    extra: [],
  },
  {
    id: 1040095,
    label: "청일",
    type: "item",
    extra: [],
  },
  {
    id: 1040096,
    label: "황일",
    type: "item",
    extra: [],
  },
  {
    id: 1040097,
    label: "녹일",
    type: "item",
    extra: [],
  },
  {
    id: 1040098,
    label: "라이트 스콜피오",
    type: "item",
    extra: [],
  },
  {
    id: 1040099,
    label: "오커 스콜피오",
    type: "item",
    extra: [],
  },
  {
    id: 1040100,
    label: "다크 스콜피오",
    type: "item",
    extra: [],
  },
  {
    id: 1040102,
    label: "브론즈 플라틴",
    type: "item",
    extra: [],
  },
  {
    id: 1040103,
    label: "미스릴 플라틴",
    type: "item",
    extra: [],
  },
  {
    id: 1040104,
    label: "오리할콘 플라틴",
    type: "item",
    extra: [],
  },
  {
    id: 1040105,
    label: "브라운 스타드",
    type: "item",
    extra: [],
  },
  {
    id: 1040106,
    label: "블루 스타드",
    type: "item",
    extra: [],
  },
  {
    id: 1040107,
    label: "다크 스타드",
    type: "item",
    extra: [],
  },
  {
    id: 1040108,
    label: "그린 피라테",
    type: "item",
    extra: [],
  },
  {
    id: 1040109,
    label: "레드 피라테",
    type: "item",
    extra: [],
  },
  {
    id: 1040110,
    label: "다크 피라테",
    type: "item",
    extra: [],
  },
  {
    id: 1040111,
    label: "그린 제너럴",
    type: "item",
    extra: [],
  },
  {
    id: 1040112,
    label: "블루 제너럴",
    type: "item",
    extra: [],
  },
  {
    id: 1040113,
    label: "다크 제너럴",
    type: "item",
    extra: [],
  },
  {
    id: 1040115,
    label: "그린 쉐이드슈트",
    type: "item",
    extra: [],
  },
  {
    id: 1040116,
    label: "브라운 쉐이드슈트",
    type: "item",
    extra: [],
  },
  {
    id: 1040121,
    label: "블루 네오스",
    type: "item",
    extra: [],
  },
  {
    id: 1041008,
    label: "그린 아벨 래더아머",
    type: "item",
    extra: [],
  },
  {
    id: 1041012,
    label: "빨간 줄무늬 티셔츠",
    type: "item",
    extra: [],
  },
  {
    id: 1041013,
    label: "그린 쉬버메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041014,
    label: "오렌지 스퀘이머",
    type: "item",
    extra: [],
  },
  {
    id: 1041017,
    label: "퍼플 아리안느",
    type: "item",
    extra: [],
  },
  {
    id: 1041018,
    label: "그린 아리안느",
    type: "item",
    extra: [],
  },
  {
    id: 1041021,
    label: "브라운 라멜",
    type: "item",
    extra: [],
  },
  {
    id: 1041023,
    label: "스카이 샤크",
    type: "item",
    extra: [],
  },
  {
    id: 1041026,
    label: "옐로우 아리안느",
    type: "item",
    extra: [],
  },
  {
    id: 1041027,
    label: "레드 쉬버메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041028,
    label: "퍼플 쉬버메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041029,
    label: "검은색 가람",
    type: "item",
    extra: [],
  },
  {
    id: 1041031,
    label: "보라색 가람",
    type: "item",
    extra: [],
  },
  {
    id: 1041032,
    label: "그린 이너스 체인메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041033,
    label: "레드 이너스 체인메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041039,
    label: "분홍색 친파오",
    type: "item",
    extra: [],
  },
  {
    id: 1041040,
    label: "파란색 친파오",
    type: "item",
    extra: [],
  },
  {
    id: 1041041,
    label: "퍼플 페어리 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1041042,
    label: "그린 페어리 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1041043,
    label: "블루 페어리 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1041044,
    label: "적야",
    type: "item",
    extra: [],
  },
  {
    id: 1041045,
    label: "황야",
    type: "item",
    extra: [],
  },
  {
    id: 1041047,
    label: "레드 스틸레스",
    type: "item",
    extra: [],
  },
  {
    id: 1041048,
    label: "블랙 스틸레스",
    type: "item",
    extra: [],
  },
  {
    id: 1041049,
    label: "블루 스틸레스",
    type: "item",
    extra: [],
  },
  {
    id: 1041050,
    label: "퍼플 스틸레스",
    type: "item",
    extra: [],
  },
  {
    id: 1041051,
    label: "레드 아모리아 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1041052,
    label: "블루 아모리아 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1041053,
    label: "블랙 아모리아 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1041054,
    label: "그린 헌터미 아머",
    type: "item",
    extra: [],
  },
  {
    id: 1041055,
    label: "블랙 헌터미 아머",
    type: "item",
    extra: [],
  },
  {
    id: 1041056,
    label: "레드 헌터미 아머",
    type: "item",
    extra: [],
  },
  {
    id: 1041058,
    label: "블러드 스니클리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041059,
    label: "스카이 스니클리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041060,
    label: "골드 스니클리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041061,
    label: "옐로우 아벨린",
    type: "item",
    extra: [],
  },
  {
    id: 1041062,
    label: "옐로우 아벨 래더아머",
    type: "item",
    extra: [],
  },
  {
    id: 1041063,
    label: "그레이 아벨 래더아머",
    type: "item",
    extra: [],
  },
  {
    id: 1041064,
    label: "블루베리 스퀘이머",
    type: "item",
    extra: [],
  },
  {
    id: 1041065,
    label: "레드 레골리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041066,
    label: "블루 레골리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041067,
    label: "그린 레골리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041068,
    label: "다크 레골리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041069,
    label: "브라운 레골리스",
    type: "item",
    extra: [],
  },
  {
    id: 1041074,
    label: "퍼플 쉐도우",
    type: "item",
    extra: [],
  },
  {
    id: 1041075,
    label: "레드 쉐도우",
    type: "item",
    extra: [],
  },
  {
    id: 1041077,
    label: "자월",
    type: "item",
    extra: [],
  },
  {
    id: 1041079,
    label: "황월",
    type: "item",
    extra: [],
  },
  {
    id: 1041080,
    label: "적월",
    type: "item",
    extra: [],
  },
  {
    id: 1041081,
    label: "화이트 피에르타",
    type: "item",
    extra: [],
  },
  {
    id: 1041082,
    label: "브라운 피에르타",
    type: "item",
    extra: [],
  },
  {
    id: 1041083,
    label: "다크 피에르타",
    type: "item",
    extra: [],
  },
  {
    id: 1041084,
    label: "홍진월갑주",
    type: "item",
    extra: [],
  },
  {
    id: 1041085,
    label: "황진월갑주",
    type: "item",
    extra: [],
  },
  {
    id: 1041086,
    label: "흑진월갑주",
    type: "item",
    extra: [],
  },
  {
    id: 1041087,
    label: "레드 숄더메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041088,
    label: "아이보리 숄더메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041089,
    label: "다크 숄더메일",
    type: "item",
    extra: [],
  },
  {
    id: 1041091,
    label: "그린 트란도트",
    type: "item",
    extra: [],
  },
  {
    id: 1041092,
    label: "레드 트란도트",
    type: "item",
    extra: [],
  },
  {
    id: 1041093,
    label: "블루 트란도트",
    type: "item",
    extra: [],
  },
  {
    id: 1041094,
    label: "라이트 맨티스",
    type: "item",
    extra: [],
  },
  {
    id: 1041095,
    label: "블러디 맨티스",
    type: "item",
    extra: [],
  },
  {
    id: 1041096,
    label: "엄버 맨티스",
    type: "item",
    extra: [],
  },
  {
    id: 1041097,
    label: "아쿠아 플라티나",
    type: "item",
    extra: [],
  },
  {
    id: 1041098,
    label: "바이올렛 플라티나",
    type: "item",
    extra: [],
  },
  {
    id: 1041099,
    label: "블러드 플라티나",
    type: "item",
    extra: [],
  },
  {
    id: 1041100,
    label: "퍼플 루티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041101,
    label: "블루 루티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041102,
    label: "핑크 루티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041103,
    label: "레드 루티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041105,
    label: "그린 피라티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041106,
    label: "레드 피라티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041107,
    label: "다크 피라티드",
    type: "item",
    extra: [],
  },
  {
    id: 1041117,
    label: "퍼플 미스트슈트",
    type: "item",
    extra: [],
  },
  {
    id: 1041118,
    label: "레드 미스트슈트",
    type: "item",
    extra: [],
  },
  {
    id: 1041119,
    label: "그린 발퀴레",
    type: "item",
    extra: [],
  },
  {
    id: 1041120,
    label: "퍼플 발퀴레",
    type: "item",
    extra: [],
  },
  {
    id: 1041121,
    label: "다크 발퀴레",
    type: "item",
    extra: [],
  },
  {
    id: 1041123,
    label: "퍼플 엘소르",
    type: "item",
    extra: [],
  },
  {
    id: 1082000,
    label: "강철 반장갑",
    type: "item",
    extra: [],
  },
  {
    id: 1082002,
    label: "노가다 목장갑",
    type: "item",
    extra: ["노목", "공노목"],
  },
  {
    id: 1082003,
    label: "주노",
    type: "item",
    extra: [],
  },
  {
    id: 1082005,
    label: "스틸 미셀",
    type: "item",
    extra: [],
  },
  {
    id: 1082006,
    label: "오리할콘 미셀",
    type: "item",
    extra: [],
  },
  {
    id: 1082007,
    label: "브론즈 미셀",
    type: "item",
    extra: [],
  },
  {
    id: 1082008,
    label: "스틸 브리건",
    type: "item",
    extra: [],
  },
  {
    id: 1082009,
    label: "스틸 브리스트",
    type: "item",
    extra: [],
  },
  {
    id: 1082010,
    label: "미스릴 브리스트",
    type: "item",
    extra: [],
  },
  {
    id: 1082011,
    label: "골드 브리스트",
    type: "item",
    extra: [],
  },
  {
    id: 1082012,
    label: "아르",
    type: "item",
    extra: [],
  },
  {
    id: 1082016,
    label: "블루 사바타",
    type: "item",
    extra: [],
  },
  {
    id: 1082017,
    label: "레드 사바타",
    type: "item",
    extra: [],
  },
  {
    id: 1082018,
    label: "다크 사바타",
    type: "item",
    extra: [],
  },
  {
    id: 1082019,
    label: "레모나",
    type: "item",
    extra: [],
  },
  {
    id: 1082023,
    label: "아이언 너클",
    type: "item",
    extra: [],
  },
  {
    id: 1082024,
    label: "아다만티움 너클",
    type: "item",
    extra: [],
  },
  {
    id: 1082025,
    label: "다크 너클",
    type: "item",
    extra: [],
  },
  {
    id: 1082026,
    label: "오션 메사나",
    type: "item",
    extra: [],
  },
  {
    id: 1082029,
    label: "브라운 듀오",
    type: "item",
    extra: [],
  },
  {
    id: 1082035,
    label: "옐로우 브리건",
    type: "item",
    extra: [],
  },
  {
    id: 1082036,
    label: "다크 브리건",
    type: "item",
    extra: [],
  },
  {
    id: 1082037,
    label: "브론즈 우드쉽",
    type: "item",
    extra: [],
  },
  {
    id: 1082038,
    label: "미스릴 우드쉽",
    type: "item",
    extra: [],
  },
  {
    id: 1082042,
    label: "스틸 실비아",
    type: "item",
    extra: [],
  },
  {
    id: 1082043,
    label: "실버 실비아",
    type: "item",
    extra: [],
  },
  {
    id: 1082044,
    label: "골드 실비아",
    type: "item",
    extra: [],
  },
  {
    id: 1082045,
    label: "골드 아르비욘",
    type: "item",
    extra: [],
  },
  {
    id: 1082046,
    label: "스틸 아르비욘",
    type: "item",
    extra: [],
  },
  {
    id: 1082047,
    label: "오리할콘 아르비욘",
    type: "item",
    extra: [],
  },
  {
    id: 1082048,
    label: "브라운 마커",
    type: "item",
    extra: [],
  },
  {
    id: 1082049,
    label: "그린 마커",
    type: "item",
    extra: [],
  },
  {
    id: 1082050,
    label: "블랙 마커",
    type: "item",
    extra: [],
  },
  {
    id: 1082051,
    label: "레드 루티아",
    type: "item",
    extra: [],
  },
  {
    id: 1082052,
    label: "블루 루티아",
    type: "item",
    extra: [],
  },
  {
    id: 1082053,
    label: "블랙 루티아",
    type: "item",
    extra: [],
  },
  {
    id: 1082054,
    label: "레드 노엘",
    type: "item",
    extra: [],
  },
  {
    id: 1082055,
    label: "블루 노엘",
    type: "item",
    extra: [],
  },
  {
    id: 1082056,
    label: "다크 노엘",
    type: "item",
    extra: [],
  },
  {
    id: 1082059,
    label: "브론즈 클랜치",
    type: "item",
    extra: [],
  },
  {
    id: 1082060,
    label: "사파이어 클랜치",
    type: "item",
    extra: [],
  },
  {
    id: 1082061,
    label: "다크 클랜치",
    type: "item",
    extra: [],
  },
  {
    id: 1082062,
    label: "레드 아르텐",
    type: "item",
    extra: [],
  },
  {
    id: 1082063,
    label: "블루 아르텐",
    type: "item",
    extra: [],
  },
  {
    id: 1082064,
    label: "다크 아르텐",
    type: "item",
    extra: [],
  },
  {
    id: 1082065,
    label: "청월 장갑",
    type: "item",
    extra: [],
  },
  {
    id: 1082066,
    label: "황월 장갑",
    type: "item",
    extra: [],
  },
  {
    id: 1082067,
    label: "적월 장갑",
    type: "item",
    extra: [],
  },
  {
    id: 1082068,
    label: "브론즈 스케일러",
    type: "item",
    extra: [],
  },
  {
    id: 1082069,
    label: "미스릴 스케일러",
    type: "item",
    extra: [],
  },
  {
    id: 1082070,
    label: "골드 스케일러",
    type: "item",
    extra: [],
  },
  {
    id: 1082071,
    label: "아쿠아 브레이스",
    type: "item",
    extra: [],
  },
  {
    id: 1082072,
    label: "골드 브레이스",
    type: "item",
    extra: [],
  },
  {
    id: 1082073,
    label: "다크 브레이스",
    type: "item",
    extra: [],
  },
  {
    id: 1082074,
    label: "다크 클리브",
    type: "item",
    extra: [],
  },
  {
    id: 1082075,
    label: "레드 클리브",
    type: "item",
    extra: [],
  },
  {
    id: 1082076,
    label: "골드 클리브",
    type: "item",
    extra: [],
  },
  {
    id: 1082080,
    label: "다크 페넌스",
    type: "item",
    extra: [],
  },
  {
    id: 1082081,
    label: "레드 페넌스",
    type: "item",
    extra: [],
  },
  {
    id: 1082082,
    label: "블루 페넌스",
    type: "item",
    extra: [],
  },
  {
    id: 1082083,
    label: "다크 윌로우",
    type: "item",
    extra: [],
  },
  {
    id: 1082084,
    label: "블루 윌로우",
    type: "item",
    extra: [],
  },
  {
    id: 1082085,
    label: "레드 윌로우",
    type: "item",
    extra: [],
  },
  {
    id: 1082086,
    label: "스틸 마누트",
    type: "item",
    extra: [],
  },
  {
    id: 1082087,
    label: "골드 마누트",
    type: "item",
    extra: [],
  },
  {
    id: 1082088,
    label: "다크 마누트",
    type: "item",
    extra: [],
  },
  {
    id: 1082089,
    label: "오커 가너",
    type: "item",
    extra: [],
  },
  {
    id: 1082090,
    label: "세피아 가너",
    type: "item",
    extra: [],
  },
  {
    id: 1082091,
    label: "다크 가너",
    type: "item",
    extra: [],
  },
  {
    id: 1082092,
    label: "브론즈 파우",
    type: "item",
    extra: [],
  },
  {
    id: 1082093,
    label: "스틸 파우",
    type: "item",
    extra: [],
  },
  {
    id: 1082094,
    label: "골드 파우",
    type: "item",
    extra: [],
  },
  {
    id: 1082095,
    label: "브론즈 와이어스",
    type: "item",
    extra: [],
  },
  {
    id: 1082096,
    label: "실버 와이어스",
    type: "item",
    extra: [],
  },
  {
    id: 1082097,
    label: "골드 와이어스",
    type: "item",
    extra: [],
  },
  {
    id: 1082098,
    label: "브라운 로린",
    type: "item",
    extra: [],
  },
  {
    id: 1082099,
    label: "블루 로린",
    type: "item",
    extra: [],
  },
  {
    id: 1082100,
    label: "다크 로린",
    type: "item",
    extra: [],
  },
  {
    id: 1082103,
    label: "브론즈 허스크",
    type: "item",
    extra: [],
  },
  {
    id: 1082104,
    label: "미스릴 허스크",
    type: "item",
    extra: [],
  },
  {
    id: 1082105,
    label: "다크 허스크",
    type: "item",
    extra: [],
  },
  {
    id: 1082106,
    label: "블루 아이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1082107,
    label: "골드 아이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1082108,
    label: "다크 아이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1082109,
    label: "레드 코든",
    type: "item",
    extra: [],
  },
  {
    id: 1082110,
    label: "블루 코든",
    type: "item",
    extra: [],
  },
  {
    id: 1082111,
    label: "그린 코든",
    type: "item",
    extra: [],
  },
  {
    id: 1082112,
    label: "다크 코든",
    type: "item",
    extra: [],
  },
  {
    id: 1082114,
    label: "사파이어 엠페러",
    type: "item",
    extra: [],
  },
  {
    id: 1082115,
    label: "에메랄드 엠페러",
    type: "item",
    extra: [],
  },
  {
    id: 1082116,
    label: "블러드 엠페러",
    type: "item",
    extra: [],
  },
  {
    id: 1082117,
    label: "다크 엠페러",
    type: "item",
    extra: [],
  },
  {
    id: 1082118,
    label: "그린 로버",
    type: "item",
    extra: [],
  },
  {
    id: 1082119,
    label: "퍼플 로버",
    type: "item",
    extra: [],
  },
  {
    id: 1082120,
    label: "블러드 로버",
    type: "item",
    extra: [],
  },
  {
    id: 1082121,
    label: "그린 와이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1082122,
    label: "블루 와이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1082123,
    label: "다크 와이즈",
    type: "item",
    extra: [],
  },
  {
    id: 1082125,
    label: "레드 아데브",
    type: "item",
    extra: [],
  },
  {
    id: 1082126,
    label: "그린 아데브",
    type: "item",
    extra: [],
  },
  {
    id: 1082127,
    label: "다크 아데브",
    type: "item",
    extra: [],
  },
  {
    id: 1082128,
    label: "그린 페로치",
    type: "item",
    extra: [],
  },
  {
    id: 1082129,
    label: "퍼플 페로치",
    type: "item",
    extra: [],
  },
  {
    id: 1082130,
    label: "다크 페로치",
    type: "item",
    extra: [],
  },
  {
    id: 1082131,
    label: "블루 파쵸네",
    type: "item",
    extra: [],
  },
  {
    id: 1082132,
    label: "그린 파쵸네",
    type: "item",
    extra: [],
  },
  {
    id: 1082133,
    label: "레드 파쵸네",
    type: "item",
    extra: [],
  },
  {
    id: 1082134,
    label: "다크 파쵸네",
    type: "item",
    extra: [],
  },
  {
    id: 1082142,
    label: "그린 미스트라",
    type: "item",
    extra: [],
  },
  {
    id: 1082143,
    label: "퍼플 미스트라",
    type: "item",
    extra: [],
  },
  {
    id: 1050000,
    label: "화이트 크로스 체인메일",
    type: "item",
    extra: [],
  },
  {
    id: 1050002,
    label: "블러드 데빌즈 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050003,
    label: "파란색 사제의 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050005,
    label: "파란색 검도복",
    type: "item",
    extra: [],
  },
  {
    id: 1050006,
    label: "빨간색 검도복",
    type: "item",
    extra: [],
  },
  {
    id: 1050010,
    label: "그린 플레로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050011,
    label: "흑룡포",
    type: "item",
    extra: [],
  },
  {
    id: 1050021,
    label: "블루 크로스 체인메일",
    type: "item",
    extra: [],
  },
  {
    id: 1050022,
    label: "다크 크로스 체인메일",
    type: "item",
    extra: [],
  },
  {
    id: 1050023,
    label: "블루 도로스 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050024,
    label: "옐로우 도로스 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050026,
    label: "흰색 사제의 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050028,
    label: "초록색 사제의 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050029,
    label: "다크 데빌즈 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050030,
    label: "블루 데빌즈 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050031,
    label: "화이트 데빌즈 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1050035,
    label: "브라운 스타라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1050036,
    label: "레드 스타라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1050037,
    label: "그린 스타라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1050038,
    label: "블루 스타라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1050039,
    label: "다크 스타라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1050045,
    label: "블루 칼라스",
    type: "item",
    extra: [],
  },
  {
    id: 1050046,
    label: "레드 칼라스",
    type: "item",
    extra: [],
  },
  {
    id: 1050047,
    label: "오렌지 칼라스",
    type: "item",
    extra: [],
  },
  {
    id: 1050048,
    label: "화이트 칼라스",
    type: "item",
    extra: [],
  },
  {
    id: 1050049,
    label: "다크 칼라스",
    type: "item",
    extra: [],
  },
  {
    id: 1050051,
    label: "적선백궁",
    type: "item",
    extra: [],
  },
  {
    id: 1050052,
    label: "청선백궁",
    type: "item",
    extra: [],
  },
  {
    id: 1050053,
    label: "블루 아나카문",
    type: "item",
    extra: [],
  },
  {
    id: 1050054,
    label: "레드 아나카문",
    type: "item",
    extra: [],
  },
  {
    id: 1050055,
    label: "화이트 아나카문",
    type: "item",
    extra: [],
  },
  {
    id: 1050056,
    label: "다크 아나카문",
    type: "item",
    extra: [],
  },
  {
    id: 1050058,
    label: "주자로",
    type: "item",
    extra: [],
  },
  {
    id: 1050059,
    label: "청자로",
    type: "item",
    extra: [],
  },
  {
    id: 1050060,
    label: "적자로",
    type: "item",
    extra: [],
  },
  {
    id: 1050061,
    label: "블루 리넥스",
    type: "item",
    extra: [],
  },
  {
    id: 1050062,
    label: "베이지 리넥스",
    type: "item",
    extra: [],
  },
  {
    id: 1050063,
    label: "그린 리넥스",
    type: "item",
    extra: [],
  },
  {
    id: 1050064,
    label: "다크 리넥스",
    type: "item",
    extra: [],
  },
  {
    id: 1050067,
    label: "블루 레퀴엠",
    type: "item",
    extra: [],
  },
  {
    id: 1050068,
    label: "레드 레퀴엠",
    type: "item",
    extra: [],
  },
  {
    id: 1050069,
    label: "브라운 레퀴엠",
    type: "item",
    extra: [],
  },
  {
    id: 1050070,
    label: "다크 레퀴엠",
    type: "item",
    extra: [],
  },
  {
    id: 1050072,
    label: "그린 크리시스",
    type: "item",
    extra: [],
  },
  {
    id: 1050073,
    label: "블루 크리시스",
    type: "item",
    extra: [],
  },
  {
    id: 1050074,
    label: "다크 크리시스",
    type: "item",
    extra: [],
  },
  {
    id: 1050075,
    label: "레드 프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1050076,
    label: "블루 프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1050077,
    label: "그린 프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1050078,
    label: "다크 프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1050080,
    label: "그린 배틀로드",
    type: "item",
    extra: [],
  },
  {
    id: 1050081,
    label: "레드 배틀로드",
    type: "item",
    extra: [],
  },
  {
    id: 1050082,
    label: "블루 배틀로드",
    type: "item",
    extra: [],
  },
  {
    id: 1050083,
    label: "다크 배틀로드",
    type: "item",
    extra: [],
  },
  {
    id: 1050088,
    label: "레드 아데스",
    type: "item",
    extra: [],
  },
  {
    id: 1050089,
    label: "블루 아데스",
    type: "item",
    extra: [],
  },
  {
    id: 1050090,
    label: "그린 아데스",
    type: "item",
    extra: [],
  },
  {
    id: 1050091,
    label: "다크 아데스",
    type: "item",
    extra: [],
  },
  {
    id: 1050094,
    label: "적견랑포",
    type: "item",
    extra: [],
  },
  {
    id: 1050095,
    label: "흑견랑포",
    type: "item",
    extra: [],
  },
  {
    id: 1050097,
    label: "블루 카티나스",
    type: "item",
    extra: [],
  },
  {
    id: 1050103,
    label: "블루 바루나",
    type: "item",
    extra: [],
  },
  {
    id: 1050107,
    label: "블루 아르주나",
    type: "item",
    extra: [],
  },
  {
    id: 1051000,
    label: "스틸 메일",
    type: "item",
    extra: [],
  },
  {
    id: 1051001,
    label: "에메랄드 메일",
    type: "item",
    extra: [],
  },
  {
    id: 1051003,
    label: "브라운 도로네스 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1051004,
    label: "퍼플 도로네스 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1051005,
    label: "레드 도로네스 로브",
    type: "item",
    extra: [],
  },
  {
    id: 1051006,
    label: "다크 후르츠",
    type: "item",
    extra: [],
  },
  {
    id: 1051007,
    label: "레드 후르츠",
    type: "item",
    extra: [],
  },
  {
    id: 1051008,
    label: "블루 후르츠",
    type: "item",
    extra: [],
  },
  {
    id: 1051009,
    label: "퍼플 후르츠",
    type: "item",
    extra: [],
  },
  {
    id: 1051010,
    label: "다크 잉그리트",
    type: "item",
    extra: [],
  },
  {
    id: 1051012,
    label: "블루 잉그리트",
    type: "item",
    extra: [],
  },
  {
    id: 1051013,
    label: "옐로우 잉그리트",
    type: "item",
    extra: [],
  },
  {
    id: 1051014,
    label: "사파이어 메일",
    type: "item",
    extra: [],
  },
  {
    id: 1051015,
    label: "블러드 메일",
    type: "item",
    extra: [],
  },
  {
    id: 1051016,
    label: "실버 메일",
    type: "item",
    extra: [],
  },
  {
    id: 1051023,
    label: "퍼플 문라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1051024,
    label: "레드 문라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1051025,
    label: "블루 문라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1051026,
    label: "다크 문라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1051027,
    label: "브라운 문라이트",
    type: "item",
    extra: [],
  },
  {
    id: 1051030,
    label: "다크 칼라프",
    type: "item",
    extra: [],
  },
  {
    id: 1051031,
    label: "화이트 칼라프",
    type: "item",
    extra: [],
  },
  {
    id: 1051032,
    label: "블루 칼라프",
    type: "item",
    extra: [],
  },
  {
    id: 1051033,
    label: "레드 칼라프",
    type: "item",
    extra: [],
  },
  {
    id: 1051034,
    label: "오렌지 칼라프",
    type: "item",
    extra: [],
  },
  {
    id: 1051037,
    label: "블루 루이마리",
    type: "item",
    extra: [],
  },
  {
    id: 1051038,
    label: "그린 루이마리",
    type: "item",
    extra: [],
  },
  {
    id: 1051039,
    label: "레드 루이마리",
    type: "item",
    extra: [],
  },
  {
    id: 1051041,
    label: "적초로",
    type: "item",
    extra: [],
  },
  {
    id: 1051042,
    label: "청초로",
    type: "item",
    extra: [],
  },
  {
    id: 1051043,
    label: "황초로",
    type: "item",
    extra: [],
  },
  {
    id: 1051044,
    label: "블루 아나카룬",
    type: "item",
    extra: [],
  },
  {
    id: 1051045,
    label: "레드 아나카룬",
    type: "item",
    extra: [],
  },
  {
    id: 1051046,
    label: "화이트 아나카룬",
    type: "item",
    extra: [],
  },
  {
    id: 1051047,
    label: "다크 아나카룬",
    type: "item",
    extra: [],
  },
  {
    id: 1051052,
    label: "블루 레퀴에르",
    type: "item",
    extra: [],
  },
  {
    id: 1051053,
    label: "레드 레퀴에르",
    type: "item",
    extra: [],
  },
  {
    id: 1051054,
    label: "브라운 레퀴에르",
    type: "item",
    extra: [],
  },
  {
    id: 1051055,
    label: "다크 레퀴에르",
    type: "item",
    extra: [],
  },
  {
    id: 1051056,
    label: "그린 크리시아",
    type: "item",
    extra: [],
  },
  {
    id: 1051057,
    label: "퍼플 크리시아",
    type: "item",
    extra: [],
  },
  {
    id: 1051058,
    label: "다크 크리시아",
    type: "item",
    extra: [],
  },
  {
    id: 1051062,
    label: "블루 리네로스",
    type: "item",
    extra: [],
  },
  {
    id: 1051063,
    label: "베이지 리네로스",
    type: "item",
    extra: [],
  },
  {
    id: 1051064,
    label: "그린 리네로스",
    type: "item",
    extra: [],
  },
  {
    id: 1051065,
    label: "다크 리네로스",
    type: "item",
    extra: [],
  },
  {
    id: 1051066,
    label: "레드 프리아",
    type: "item",
    extra: [],
  },
  {
    id: 1051067,
    label: "블루 프리아",
    type: "item",
    extra: [],
  },
  {
    id: 1051068,
    label: "그린 프리아",
    type: "item",
    extra: [],
  },
  {
    id: 1051069,
    label: "다크 프리아",
    type: "item",
    extra: [],
  },
  {
    id: 1051077,
    label: "옐로우 배틀엠프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1051078,
    label: "레드 배틀엠프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1051079,
    label: "블루 배틀엠프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1051080,
    label: "다크 배틀엠프리스",
    type: "item",
    extra: [],
  },
  {
    id: 1051082,
    label: "레드 아네스",
    type: "item",
    extra: [],
  },
  {
    id: 1051083,
    label: "블루 아네스",
    type: "item",
    extra: [],
  },
  {
    id: 1051084,
    label: "그린 아네스",
    type: "item",
    extra: [],
  },
  {
    id: 1051085,
    label: "다크 아네스",
    type: "item",
    extra: [],
  },
  {
    id: 1051091,
    label: "블루 카테",
    type: "item",
    extra: [],
  },
  {
    id: 1051094,
    label: "녹견랑포",
    type: "item",
    extra: [],
  },
  {
    id: 1051095,
    label: "청견랑포",
    type: "item",
    extra: [],
  },
  {
    id: 1051102,
    label: "블루 바즈라",
    type: "item",
    extra: [],
  },
  {
    id: 1051106,
    label: "블루 아르미스",
    type: "item",
    extra: [],
  },
  {
    id: 1060000,
    label: "흑진일갑주 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060004,
    label: "회색 고무줄 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060009,
    label: "스틸 코퍼럴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060010,
    label: "스틸 서전트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1060011,
    label: "오리할콘 마스터 서전트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1060013,
    label: "회갈색 수련복 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060014,
    label: "검은색 가람 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060015,
    label: "흰색 가람 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060016,
    label: "흑무 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060017,
    label: "백무 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060018,
    label: "홍무 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060019,
    label: "황무 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060020,
    label: "반 백무 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060024,
    label: "흑야 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060025,
    label: "청야 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060027,
    label: "브라운 코퍼럴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060030,
    label: "다크 마스터 서전트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1060031,
    label: "파란색 파오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060032,
    label: "빨간색 파오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060033,
    label: "검정색 파오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060038,
    label: "브라운 스니크 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060043,
    label: "다크브라운 스틸러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060044,
    label: "다크실버 스틸러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060045,
    label: "레드골드 스틸러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060046,
    label: "실버블랙 스틸러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060050,
    label: "블루 너클베스트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060051,
    label: "레드 너클베스트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060052,
    label: "블랙 너클베스트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060058,
    label: "레드 헌터스 아머 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060059,
    label: "블루 헌터스 아머 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060060,
    label: "실버 마스터 서전트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1060061,
    label: "레드 레골러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060062,
    label: "블루 레골러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060063,
    label: "그린 레골러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060064,
    label: "다크 레골러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060065,
    label: "브라운 레골러 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060069,
    label: "브라운 피에뜨 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060070,
    label: "블루 피에뜨 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060071,
    label: "카키 쉐도우 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060072,
    label: "마린 쉐도우 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060073,
    label: "다크 쉐도우 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060074,
    label: "백진일갑주 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060075,
    label: "황진일갑주 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060076,
    label: "블루 숄더메일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060077,
    label: "오우커 숄더메일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060078,
    label: "엄버 숄더메일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060079,
    label: "그린 오리엔타이칸 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060080,
    label: "레드 오리엔타이칸 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060081,
    label: "블루 오리엔타이칸 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060082,
    label: "다크 오리엔타이칸 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060083,
    label: "적일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060084,
    label: "청일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060085,
    label: "황일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060086,
    label: "녹일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060087,
    label: "라이트 스콜피오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060088,
    label: "오커 스콜피오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060089,
    label: "다크 스콜피오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060090,
    label: "브론즈 플라틴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060091,
    label: "미스릴 플라틴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060092,
    label: "오리할콘 플라틴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060093,
    label: "브라운 스타드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060094,
    label: "블루 스타드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060095,
    label: "다크 스타드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060097,
    label: "그린 피라테 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060098,
    label: "레드 피라테 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060099,
    label: "다크 피라테 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060100,
    label: "그린 제너럴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060101,
    label: "블루 제너럴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060102,
    label: "다크 제너럴 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060104,
    label: "그린 쉐이드슈트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060105,
    label: "브라운 쉐이드슈트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060106,
    label: "퍼플 쉐이드슈트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060107,
    label: "레드 쉐이드슈트 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1060110,
    label: "블루 네오스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061006,
    label: "그린 아벨 가죽치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061009,
    label: "그린 아벨린 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061012,
    label: "퍼플 아리안느 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061013,
    label: "그린 아리안느 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061018,
    label: "브라운 라멜 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061019,
    label: "스카이 샤크 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061022,
    label: "옐로우 아리안느 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061024,
    label: "그린 쉬버메일 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061025,
    label: "레드 쉬버메일 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061026,
    label: "퍼플 쉬버메일 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061027,
    label: "검은색 가람 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061028,
    label: "흰색 가람 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061029,
    label: "홍몽 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061033,
    label: "파란색 친파오 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061034,
    label: "퍼플 페어리 로브 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061035,
    label: "그린 페어리 로브 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061036,
    label: "블루 페어리 로브 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061037,
    label: "적야 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061038,
    label: "황야 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061041,
    label: "보라색 친파오 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061043,
    label: "레드 스틸레스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061044,
    label: "블랙 스틸레스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061045,
    label: "블루 스틸레스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061046,
    label: "퍼플 스틸레스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061047,
    label: "레드 아모리아 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061048,
    label: "블루 아모리아 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061049,
    label: "블랙 아모리아 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061050,
    label: "그린 헌터미 아머 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061051,
    label: "블랙 헌터미 아머 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061052,
    label: "레드 헌터미 아머 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061054,
    label: "블러드 스니클리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061055,
    label: "스카이 스니클리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061056,
    label: "골드 스니클리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061057,
    label: "옐로우 아벨린 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061058,
    label: "브라운 아벨 가죽치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061059,
    label: "그레이 아벨 가죽치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061060,
    label: "레드 레골리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061061,
    label: "블루 레골리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061062,
    label: "그린 레골리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061063,
    label: "다크 레골리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061064,
    label: "브라운 레골리스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061069,
    label: "퍼플 쉐도우 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061070,
    label: "레드 쉐도우 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061076,
    label: "자월 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061077,
    label: "청월 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061078,
    label: "황월 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061079,
    label: "적월 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061080,
    label: "화이트 피에르타 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061081,
    label: "브라운 피에르타 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061082,
    label: "다크 피에르타 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061083,
    label: "홍진월갑주 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061084,
    label: "황진월갑주 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061085,
    label: "흑진월갑주 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061086,
    label: "레드 숄더메일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061087,
    label: "아이보리 숄더메일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061088,
    label: "다크 숄더메일 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061090,
    label: "그린 트란도트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061091,
    label: "레드 트란도트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061092,
    label: "블루 트란도트 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061093,
    label: "라이트 맨티스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061094,
    label: "블러디 맨티스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061095,
    label: "엄버 맨티스 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061096,
    label: "아쿠아 플라티나 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061097,
    label: "바이올렛 플라티나 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061098,
    label: "블러드 플라티나 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061099,
    label: "퍼플 루티드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061100,
    label: "블루 루티드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061101,
    label: "핑크 루티드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061102,
    label: "레드 루티드 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1061104,
    label: "그린 피라티드 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061105,
    label: "레드 피라티드 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061106,
    label: "다크 피라티드 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061118,
    label: "그린 발퀴레 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061119,
    label: "퍼플 발퀴레 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061120,
    label: "블러드 발퀴레 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1061122,
    label: "퍼플 엘소르 치마",
    type: "item",
    extra: [],
  },
  {
    id: 1062000,
    label: "아이스 진",
    type: "item",
    extra: [],
  },
  {
    id: 1062002,
    label: "갈색 하드래더 바지",
    type: "item",
    extra: [],
  },
  {
    id: 1062006,
    label: "이너스 체인바지",
    type: "item",
    extra: [],
  },
  {
    id: 1092000,
    label: "강철 방패",
    type: "item",
    extra: [],
  },
  {
    id: 1092002,
    label: "레드 크로스 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092003,
    label: "사각 나무 방패",
    type: "item",
    extra: [],
  },
  {
    id: 1092004,
    label: "해골 방패",
    type: "item",
    extra: [],
  },
  {
    id: 1092006,
    label: "미스릴 버클러",
    type: "item",
    extra: [],
  },
  {
    id: 1092007,
    label: "배틀 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092008,
    label: "냄비 뚜껑",
    type: "item",
    extra: [],
  },
  {
    id: 1092009,
    label: "우드 레전드 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092010,
    label: "실버 레전드 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092011,
    label: "아다만티움 레전드 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092012,
    label: "스틸 타워 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092013,
    label: "미스릴 타워 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092014,
    label: "아다만티움 타워 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092015,
    label: "스틸 에이션트 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092016,
    label: "실버 에이션트 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092017,
    label: "골드 에이션트 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092019,
    label: "님블 리스트",
    type: "item",
    extra: [],
  },
  {
    id: 1092020,
    label: "주르건 리스트",
    type: "item",
    extra: [],
  },
  {
    id: 1092021,
    label: "미스틱 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092023,
    label: "스틸 아퀼라 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092024,
    label: "실버 아퀼라 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092025,
    label: "골드 아퀼라 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1092026,
    label: "브론즈 칼칸",
    type: "item",
    extra: [],
  },
  {
    id: 1092027,
    label: "실버 칼칸",
    type: "item",
    extra: [],
  },
  {
    id: 1092028,
    label: "골드 칼칸",
    type: "item",
    extra: [],
  },
  {
    id: 1092029,
    label: "에스터 실드",
    type: "item",
    extra: [],
  },
  {
    id: 1072000,
    label: "황 진월장화",
    type: "item",
    extra: [],
  },
  {
    id: 1072002,
    label: "스틸 티거",
    type: "item",
    extra: [],
  },
  {
    id: 1072003,
    label: "에메랄드 배틀 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072004,
    label: "흰색 고무신",
    type: "item",
    extra: [],
  },
  {
    id: 1072007,
    label: "브라운 미들 래더",
    type: "item",
    extra: [],
  },
  {
    id: 1072008,
    label: "고동색 아로아 슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072015,
    label: "브라운 하드래더 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072019,
    label: "블루 쥬얼리 슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072020,
    label: "퍼플 쥬얼리 슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072023,
    label: "베이지 니티",
    type: "item",
    extra: [],
  },
  {
    id: 1072027,
    label: "브라운 잭부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072031,
    label: "빨간색 닌자 샌들",
    type: "item",
    extra: [],
  },
  {
    id: 1072032,
    label: "브론즈 체인슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072033,
    label: "아이언 체인슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072035,
    label: "실버 체인슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072036,
    label: "골드 체인슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072039,
    label: "미스릴 배틀 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072040,
    label: "실버 배틀 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072041,
    label: "블러드 배틀 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072046,
    label: "오렌지 미들 래더",
    type: "item",
    extra: [],
  },
  {
    id: 1072051,
    label: "실버 워 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072053,
    label: "골드 워 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072054,
    label: "주황색 가죽 구두",
    type: "item",
    extra: [],
  },
  {
    id: 1072060,
    label: "브라운 우드탑",
    type: "item",
    extra: [],
  },
  {
    id: 1072065,
    label: "빨간색 에나멜 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072066,
    label: "파란색 에나멜 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072069,
    label: "레드 잭부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072073,
    label: "옐로우 윈드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072075,
    label: "레드 매직슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072076,
    label: "블루 매직슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072077,
    label: "화이트 매직슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072078,
    label: "블랙 매직슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072079,
    label: "레드 헌터부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072080,
    label: "블루 헌터부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072081,
    label: "그린 헌터부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072082,
    label: "블랙 헌터부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072083,
    label: "브라운 헌터부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072085,
    label: "레드 래피 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072086,
    label: "그린 래피 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072087,
    label: "블랙 래피 부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072089,
    label: "퍼플 솔트 슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072090,
    label: "레드 솔트 슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072091,
    label: "블랙 솔트 슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072101,
    label: "블루 실키부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072102,
    label: "그린 실키부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072103,
    label: "레드 실키부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072104,
    label: "레드 라인부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072105,
    label: "그린 라인부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072106,
    label: "블루 라인부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072107,
    label: "레드 하프슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072108,
    label: "그린 하프슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072109,
    label: "옐로우 하프슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072110,
    label: "블루 하프슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072112,
    label: "미스릴 티거",
    type: "item",
    extra: [],
  },
  {
    id: 1072113,
    label: "다크 티거",
    type: "item",
    extra: [],
  },
  {
    id: 1072114,
    label: "레드 문슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072115,
    label: "블루 문슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072116,
    label: "골드 문슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072117,
    label: "다크 문슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072118,
    label: "레드 피에르슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072119,
    label: "옐로우 피에르슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072120,
    label: "브라운 피에르슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072121,
    label: "블루 피에르슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072122,
    label: "브라운 네일슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072123,
    label: "그린 네일슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072124,
    label: "블루 네일슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072125,
    label: "퍼플 네일슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072126,
    label: "자 진월장화",
    type: "item",
    extra: [],
  },
  {
    id: 1072127,
    label: "청 진월장화",
    type: "item",
    extra: [],
  },
  {
    id: 1072128,
    label: "블루 고니슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072129,
    label: "그린 고니슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072130,
    label: "레드 고니슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072131,
    label: "퍼플 고니슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072132,
    label: "에메랄드 힐던부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072133,
    label: "미스릴 힐던부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072134,
    label: "오리할콘 힐던부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072135,
    label: "골드 힐던부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072136,
    label: "핑크 앵클부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072137,
    label: "그린 앵클부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072138,
    label: "오렌지 앵클부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072139,
    label: "블루 앵클부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072140,
    label: "핑크 골든윈드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072141,
    label: "블루 골든윈드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072142,
    label: "퍼플 골든윈드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072143,
    label: "그린 골든윈드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072144,
    label: "레드 고어부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072145,
    label: "블루 고어부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072146,
    label: "그린 고어부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072147,
    label: "사파이어 카멜부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072148,
    label: "오리할콘 카멜부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072149,
    label: "블러드 카멜부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072150,
    label: "블러드 모스부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072151,
    label: "골드 모스부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072152,
    label: "다크 모스부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072154,
    label: "블루 카젠부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072155,
    label: "퍼플 카젠부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072156,
    label: "다크 카젠부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072157,
    label: "블루 라피스샌들",
    type: "item",
    extra: [],
  },
  {
    id: 1072158,
    label: "레드 라피스샌들",
    type: "item",
    extra: [],
  },
  {
    id: 1072159,
    label: "브라운 라피스샌들",
    type: "item",
    extra: [],
  },
  {
    id: 1072160,
    label: "골드 라피스샌들",
    type: "item",
    extra: [],
  },
  {
    id: 1072161,
    label: "퍼플 루티드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072162,
    label: "블루 루티드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072163,
    label: "레드 루티드슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072164,
    label: "블루 리넥스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072165,
    label: "베이지 리넥스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072166,
    label: "그린 리넥스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072167,
    label: "다크 리넥스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072172,
    label: "그린 피레타부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072173,
    label: "레드 피레타부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072174,
    label: "다크 피레타부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072177,
    label: "그린 크리시아슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072178,
    label: "퍼플 크리시아슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072179,
    label: "다크 크리시아슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072182,
    label: "블루 윙부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072183,
    label: "레드 윙부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072184,
    label: "그린 윙부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072185,
    label: "다크 윙부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072192,
    label: "그린 쉐도우부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072193,
    label: "브라운 쉐도우부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072194,
    label: "퍼플 쉐도우부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072195,
    label: "레드 쉐도우부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072196,
    label: "에메랄드 워 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072197,
    label: "오리할콘 워 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072198,
    label: "다크 워 그리브",
    type: "item",
    extra: [],
  },
  {
    id: 1072203,
    label: "레드 아데스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072204,
    label: "그린 아데스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072205,
    label: "다크 아데스슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072206,
    label: "블루 넬리슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072207,
    label: "그린 넬리슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072208,
    label: "레드 넬리슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072209,
    label: "다크 넬리슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072210,
    label: "레드 리버스부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072211,
    label: "블루 리버스부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072212,
    label: "다크 리버스부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072213,
    label: "그린 가티나부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072220,
    label: "그린 크리센트부츠",
    type: "item",
    extra: [],
  },
  {
    id: 1072223,
    label: "그린 바르슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072224,
    label: "블루 바르슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1072227,
    label: "레드 아르나슈즈",
    type: "item",
    extra: [],
  },
  {
    id: 1302002,
    label: "바이킹 소드",
    type: "item",
    extra: [],
  },
  {
    id: 1302003,
    label: "일룬",
    type: "item",
    extra: [],
  },
  {
    id: 1302004,
    label: "커틀러스",
    type: "item",
    extra: [],
  },
  {
    id: 1302009,
    label: "트라우스",
    type: "item",
    extra: [],
  },
  {
    id: 1302010,
    label: "쥬얼 쿠아다라",
    type: "item",
    extra: [],
  },
  {
    id: 1302011,
    label: "네오코라",
    type: "item",
    extra: [],
  },
  {
    id: 1302012,
    label: "레드 카타나",
    type: "item",
    extra: [],
  },
  {
    id: 1302013,
    label: "붉은 채찍",
    type: "item",
    extra: [],
  },
  {
    id: 1302016,
    label: "노란색 우산",
    type: "item",
    extra: [],
  },
  {
    id: 1302017,
    label: "하늘색 우산",
    type: "item",
    extra: [],
  },
  {
    id: 1302018,
    label: "아츠",
    type: "item",
    extra: [],
  },
  {
    id: 1302023,
    label: "프라우테",
    type: "item",
    extra: [],
  },
  {
    id: 1312000,
    label: "양날 도끼",
    type: "item",
    extra: [],
  },
  {
    id: 1312003,
    label: "미스릴 도끼",
    type: "item",
    extra: [],
  },
  {
    id: 1312005,
    label: "파이어 잭",
    type: "item",
    extra: [],
  },
  {
    id: 1312006,
    label: "당커",
    type: "item",
    extra: [],
  },
  {
    id: 1312007,
    label: "블루 카운터",
    type: "item",
    extra: [],
  },
  {
    id: 1312008,
    label: "벅",
    type: "item",
    extra: [],
  },
  {
    id: 1312009,
    label: "호크헤드",
    type: "item",
    extra: [],
  },
  {
    id: 1312010,
    label: "미하일",
    type: "item",
    extra: [],
  },
  {
    id: 1312011,
    label: "리프 엑스",
    type: "item",
    extra: [],
  },
  {
    id: 1312015,
    label: "비펜니스",
    type: "item",
    extra: [],
  },
  {
    id: 1312016,
    label: "콘트라 엑스",
    type: "item",
    extra: [],
  },
  {
    id: 1322000,
    label: "메이스",
    type: "item",
    extra: [],
  },
  {
    id: 1322002,
    label: "강철 메이스",
    type: "item",
    extra: [],
  },
  {
    id: 1322003,
    label: "막대 사탕",
    type: "item",
    extra: [],
  },
  {
    id: 1322004,
    label: "퓨전 메이스",
    type: "item",
    extra: [],
  },
  {
    id: 1322007,
    label: "가죽 핸드백",
    type: "item",
    extra: [],
  },
  {
    id: 1322009,
    label: "뚜러",
    type: "item",
    extra: [],
  },
  {
    id: 1322014,
    label: "워해머",
    type: "item",
    extra: [],
  },
  {
    id: 1322015,
    label: "호스맨즈",
    type: "item",
    extra: [],
  },
  {
    id: 1322016,
    label: "젝커",
    type: "item",
    extra: [],
  },
  {
    id: 1322017,
    label: "너클메이스",
    type: "item",
    extra: [],
  },
  {
    id: 1322018,
    label: "타무스",
    type: "item",
    extra: [],
  },
  {
    id: 1322019,
    label: "스튬",
    type: "item",
    extra: [],
  },
  {
    id: 1322020,
    label: "모던스튬",
    type: "item",
    extra: [],
  },
  {
    id: 1322021,
    label: "검은색 튜브",
    type: "item",
    extra: [],
  },
  {
    id: 1322022,
    label: "빨간색 꽃무늬 튜브",
    type: "item",
    extra: [],
  },
  {
    id: 1322023,
    label: "파란색 꽃무늬 튜브",
    type: "item",
    extra: [],
  },
  {
    id: 1322024,
    label: "보라색 튜브",
    type: "item",
    extra: [],
  },
  {
    id: 1322026,
    label: "파노 튜브",
    type: "item",
    extra: [],
  },
  {
    id: 1322027,
    label: "프라이팬",
    type: "item",
    extra: [],
  },
  {
    id: 1322028,
    label: "골든해머",
    type: "item",
    extra: [],
  },
  {
    id: 1322029,
    label: "루인해머",
    type: "item",
    extra: [],
  },
  {
    id: 1322045,
    label: "배틀해머",
    type: "item",
    extra: [],
  },
  {
    id: 1332000,
    label: "삼각 자마다르",
    type: "item",
    extra: [],
  },
  {
    id: 1332001,
    label: "반월 자마다르",
    type: "item",
    extra: [],
  },
  {
    id: 1332003,
    label: "신기타",
    type: "item",
    extra: [],
  },
  {
    id: 1332004,
    label: "쌍지단도",
    type: "item",
    extra: [],
  },
  {
    id: 1332006,
    label: "필드 대거",
    type: "item",
    extra: [],
  },
  {
    id: 1332008,
    label: "넙치검",
    type: "item",
    extra: [],
  },
  {
    id: 1332009,
    label: "카스",
    type: "item",
    extra: [],
  },
  {
    id: 1332010,
    label: "아이언 대거",
    type: "item",
    extra: [],
  },
  {
    id: 1332011,
    label: "베즐러드",
    type: "item",
    extra: [],
  },
  {
    id: 1332012,
    label: "리프 크로",
    type: "item",
    extra: [],
  },
  {
    id: 1332013,
    label: "스팅어",
    type: "item",
    extra: [],
  },
  {
    id: 1332014,
    label: "게파트",
    type: "item",
    extra: [],
  },
  {
    id: 1332015,
    label: "게타",
    type: "item",
    extra: [],
  },
  {
    id: 1332016,
    label: "차",
    type: "item",
    extra: [],
  },
  {
    id: 1332017,
    label: "스네이크",
    type: "item",
    extra: [],
  },
  {
    id: 1332018,
    label: "칸디네",
    type: "item",
    extra: [],
  },
  {
    id: 1332019,
    label: "금강저",
    type: "item",
    extra: [],
  },
  {
    id: 1332020,
    label: "태극부채",
    type: "item",
    extra: [],
  },
  {
    id: 1332022,
    label: "카타르",
    type: "item",
    extra: [],
  },
  {
    id: 1332023,
    label: "용천권",
    type: "item",
    extra: [],
  },
  {
    id: 1332026,
    label: "커세어",
    type: "item",
    extra: [],
  },
  {
    id: 1332027,
    label: "바키트",
    type: "item",
    extra: [],
  },
  {
    id: 1332031,
    label: "드래곤 토네일",
    type: "item",
    extra: [],
  },
  {
    id: 1332051,
    label: "골드 트윈 나이프",
    type: "item",
    extra: [],
  },
  {
    id: 1372000,
    label: "페어리 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372001,
    label: "위저드 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372002,
    label: "메탈 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372003,
    label: "미스릴 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372004,
    label: "아이스 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372007,
    label: "크로미",
    type: "item",
    extra: [],
  },
  {
    id: 1372009,
    label: "마기코라스",
    type: "item",
    extra: [],
  },
  {
    id: 1372010,
    label: "다이몬의 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372012,
    label: "크리스탈 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372014,
    label: "이블테일러",
    type: "item",
    extra: [],
  },
  {
    id: 1372016,
    label: "피닉스 완드",
    type: "item",
    extra: [],
  },
  {
    id: 1372021,
    label: "엔젤윙즈",
    type: "item",
    extra: [],
  },
  {
    id: 1382000,
    label: "나무 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382001,
    label: "아크 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382002,
    label: "위저드 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382004,
    label: "고목나무 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382006,
    label: "쏜즈",
    type: "item",
    extra: [],
  },
  {
    id: 1382007,
    label: "이블윙즈",
    type: "item",
    extra: [],
  },
  {
    id: 1382010,
    label: "레이든 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382017,
    label: "써클윈드 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382018,
    label: "페탈 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382019,
    label: "홀 스태프",
    type: "item",
    extra: [],
  },
  {
    id: 1382035,
    label: "블루마린",
    type: "item",
    extra: [],
  },
  {
    id: 1402000,
    label: "양손검",
    type: "item",
    extra: [],
  },
  {
    id: 1402001,
    label: "목검",
    type: "item",
    extra: [],
  },
  {
    id: 1402002,
    label: "왕푸",
    type: "item",
    extra: [],
  },
  {
    id: 1402003,
    label: "호검",
    type: "item",
    extra: [],
  },
  {
    id: 1402004,
    label: "청운검",
    type: "item",
    extra: [],
  },
  {
    id: 1402005,
    label: "참마도",
    type: "item",
    extra: [],
  },
  {
    id: 1402006,
    label: "하이랜더",
    type: "item",
    extra: [],
  },
  {
    id: 1402007,
    label: "쟈드",
    type: "item",
    extra: [],
  },
  {
    id: 1402008,
    label: "클러디",
    type: "item",
    extra: [],
  },
  {
    id: 1402009,
    label: "나무 야구 방망이",
    type: "item",
    extra: [],
  },
  {
    id: 1402010,
    label: "알루미늄 야구 방망이",
    type: "item",
    extra: [],
  },
  {
    id: 1402011,
    label: "그리스",
    type: "item",
    extra: [],
  },
  {
    id: 1402012,
    label: "그륜힐",
    type: "item",
    extra: [],
  },
  {
    id: 1402015,
    label: "그레이트 로헨",
    type: "item",
    extra: [],
  },
  {
    id: 1402018,
    label: "환목검",
    type: "item",
    extra: [],
  },
  {
    id: 1412000,
    label: "양손 도끼",
    type: "item",
    extra: [],
  },
  {
    id: 1412001,
    label: "쇠 도끼",
    type: "item",
    extra: [],
  },
  {
    id: 1412002,
    label: "강철 도끼",
    type: "item",
    extra: [],
  },
  {
    id: 1412003,
    label: "라이징",
    type: "item",
    extra: [],
  },
  {
    id: 1412004,
    label: "니암",
    type: "item",
    extra: [],
  },
  {
    id: 1412005,
    label: "버드빌",
    type: "item",
    extra: [],
  },
  {
    id: 1412006,
    label: "버크",
    type: "item",
    extra: [],
  },
  {
    id: 1412007,
    label: "샤이닝",
    type: "item",
    extra: [],
  },
  {
    id: 1412008,
    label: "크로노",
    type: "item",
    extra: [],
  },
  {
    id: 1412009,
    label: "헬리오스",
    type: "item",
    extra: [],
  },
  {
    id: 1412010,
    label: "클로니안 엑스",
    type: "item",
    extra: [],
  },
  {
    id: 1412021,
    label: "타바르",
    type: "item",
    extra: [],
  },
  {
    id: 1422000,
    label: "나무 망치",
    type: "item",
    extra: [],
  },
  {
    id: 1422001,
    label: "미스릴 모울",
    type: "item",
    extra: [],
  },
  {
    id: 1422005,
    label: "골든 모울",
    type: "item",
    extra: [],
  },
  {
    id: 1422007,
    label: "타이탄",
    type: "item",
    extra: [],
  },
  {
    id: 1422008,
    label: "빅해머",
    type: "item",
    extra: [],
  },
  {
    id: 1422009,
    label: "플루튬",
    type: "item",
    extra: [],
  },
  {
    id: 1422010,
    label: "호프만",
    type: "item",
    extra: [],
  },
  {
    id: 1422012,
    label: "크롬",
    type: "item",
    extra: [],
  },
  {
    id: 1432000,
    label: "창",
    type: "item",
    extra: [],
  },
  {
    id: 1432001,
    label: "포크 창",
    type: "item",
    extra: [],
  },
  {
    id: 1432003,
    label: "나카마키",
    type: "item",
    extra: [],
  },
  {
    id: 1432004,
    label: "장팔사모",
    type: "item",
    extra: [],
  },
  {
    id: 1432005,
    label: "제코",
    type: "item",
    extra: [],
  },
  {
    id: 1432006,
    label: "십자창",
    type: "item",
    extra: [],
  },
  {
    id: 1432007,
    label: "스페판",
    type: "item",
    extra: [],
  },
  {
    id: 1432008,
    label: "물고기 작살",
    type: "item",
    extra: [],
  },
  {
    id: 1432010,
    label: "호진공창",
    type: "item",
    extra: [],
  },
  {
    id: 1432030,
    label: "피나카",
    type: "item",
    extra: [],
  },
  {
    id: 1442000,
    label: "폴암",
    type: "item",
    extra: [],
  },
  {
    id: 1442001,
    label: "미스릴 폴암",
    type: "item",
    extra: [],
  },
  {
    id: 1442003,
    label: "도끼 폴암",
    type: "item",
    extra: [],
  },
  {
    id: 1442005,
    label: "구룡도",
    type: "item",
    extra: [],
  },
  {
    id: 1442008,
    label: "황룡도",
    type: "item",
    extra: [],
  },
  {
    id: 1442009,
    label: "크레센트",
    type: "item",
    extra: [],
  },
  {
    id: 1442010,
    label: "방천극",
    type: "item",
    extra: [],
  },
  {
    id: 1442011,
    label: "서핑보드",
    type: "item",
    extra: [],
  },
  {
    id: 1442019,
    label: "월아산",
    type: "item",
    extra: [],
  },
  {
    id: 1452000,
    label: "배틀 보우",
    type: "item",
    extra: [],
  },
  {
    id: 1452001,
    label: "사냥꾼의 활",
    type: "item",
    extra: [],
  },
  {
    id: 1452003,
    label: "합금 활",
    type: "item",
    extra: [],
  },
  {
    id: 1452004,
    label: "봉황위궁",
    type: "item",
    extra: [],
  },
  {
    id: 1452005,
    label: "라이덴",
    type: "item",
    extra: [],
  },
  {
    id: 1452006,
    label: "레드 바이퍼",
    type: "item",
    extra: [],
  },
  {
    id: 1452007,
    label: "발터2000",
    type: "item",
    extra: [],
  },
  {
    id: 1452008,
    label: "올림푸스",
    type: "item",
    extra: [],
  },
  {
    id: 1452009,
    label: "레드 힌켈",
    type: "item",
    extra: [],
  },
  {
    id: 1452010,
    label: "블루 힌켈",
    type: "item",
    extra: [],
  },
  {
    id: 1452011,
    label: "골든 힌켈",
    type: "item",
    extra: [],
  },
  {
    id: 1452012,
    label: "마린 아룬드",
    type: "item",
    extra: [],
  },
  {
    id: 1452013,
    label: "파이어 아룬드",
    type: "item",
    extra: [],
  },
  {
    id: 1452014,
    label: "골든 아룬드",
    type: "item",
    extra: [],
  },
  {
    id: 1452015,
    label: "다크 아룬드",
    type: "item",
    extra: [],
  },
  {
    id: 1452019,
    label: "화이트 니스록",
    type: "item",
    extra: [],
  },
  {
    id: 1452020,
    label: "골든 니스록",
    type: "item",
    extra: [],
  },
  {
    id: 1462000,
    label: "산양 석궁",
    type: "item",
    extra: [],
  },
  {
    id: 1462002,
    label: "전투 석궁",
    type: "item",
    extra: [],
  },
  {
    id: 1462003,
    label: "발란쉐",
    type: "item",
    extra: [],
  },
  {
    id: 1462004,
    label: "이글크로",
    type: "item",
    extra: [],
  },
  {
    id: 1462005,
    label: "헤클러",
    type: "item",
    extra: [],
  },
  {
    id: 1462006,
    label: "실버 크로우",
    type: "item",
    extra: [],
  },
  {
    id: 1462007,
    label: "로우어",
    type: "item",
    extra: [],
  },
  {
    id: 1462008,
    label: "골든 크로우",
    type: "item",
    extra: [],
  },
  {
    id: 1462009,
    label: "그로스야거",
    type: "item",
    extra: [],
  },
  {
    id: 1462010,
    label: "마린 샬리트",
    type: "item",
    extra: [],
  },
  {
    id: 1462011,
    label: "파이어 샬리트",
    type: "item",
    extra: [],
  },
  {
    id: 1462012,
    label: "골든 샬리트",
    type: "item",
    extra: [],
  },
  {
    id: 1462013,
    label: "다크 샬리트",
    type: "item",
    extra: [],
  },
  {
    id: 1462015,
    label: "화이트 네쉐르",
    type: "item",
    extra: [],
  },
  {
    id: 1462016,
    label: "골든 네쉐르",
    type: "item",
    extra: [],
  },
  {
    id: 1462018,
    label: "카사 크로우",
    type: "item",
    extra: [],
  },
  {
    id: 1472000,
    label: "가니어",
    type: "item",
    extra: [],
  },
  {
    id: 1472006,
    label: "아다만티움 이고르",
    type: "item",
    extra: [],
  },
  {
    id: 1472007,
    label: "메바",
    type: "item",
    extra: ["떡메바"],
  },
  {
    id: 1472009,
    label: "미스릴 가즈",
    type: "item",
    extra: [],
  },
  {
    id: 1472010,
    label: "아다만티움 가즈",
    type: "item",
    extra: [],
  },
  {
    id: 1472011,
    label: "브론즈 가디언",
    type: "item",
    extra: [],
  },
  {
    id: 1472012,
    label: "실버 가디언",
    type: "item",
    extra: [],
  },
  {
    id: 1472013,
    label: "다크 가디언",
    type: "item",
    extra: [],
  },
  {
    id: 1472014,
    label: "스틸 보닌",
    type: "item",
    extra: [],
  },
  {
    id: 1472015,
    label: "블러드 보닌",
    type: "item",
    extra: [],
  },
  {
    id: 1472016,
    label: "아다만티움 보닌",
    type: "item",
    extra: [],
  },
  {
    id: 1472017,
    label: "다크 보닌",
    type: "item",
    extra: [],
  },
  {
    id: 1472018,
    label: "스틸 슬레인",
    type: "item",
    extra: [],
  },
  {
    id: 1472019,
    label: "블러드 슬레인",
    type: "item",
    extra: [],
  },
  {
    id: 1472020,
    label: "사파이어 슬레인",
    type: "item",
    extra: [],
  },
  {
    id: 1472021,
    label: "다크 슬레인",
    type: "item",
    extra: [],
  },
  {
    id: 1472022,
    label: "브론즈 기간틱",
    type: "item",
    extra: [],
  },
  {
    id: 1472023,
    label: "블러드 기간틱",
    type: "item",
    extra: [],
  },
  {
    id: 1472024,
    label: "사파이어 기간틱",
    type: "item",
    extra: [],
  },
  {
    id: 1472025,
    label: "다크 기간틱",
    type: "item",
    extra: [],
  },
  {
    id: 1472026,
    label: "황갑충",
    type: "item",
    extra: [],
  },
  {
    id: 1472027,
    label: "녹갑충",
    type: "item",
    extra: [],
  },
  {
    id: 1472028,
    label: "청갑충",
    type: "item",
    extra: [],
  },
  {
    id: 1472029,
    label: "흑갑충",
    type: "item",
    extra: [],
  },
  {
    id: 1472031,
    label: "코브라스티어",
    type: "item",
    extra: [],
  },
  {
    id: 4000000,
    label: "파란 달팽이의 껍질",
    type: "item",
    extra: [],
  },
  {
    id: 4000001,
    label: "주황버섯의 갓",
    type: "item",
    extra: [],
  },
  {
    id: 4000002,
    label: "돼지의 리본",
    type: "item",
    extra: [],
  },
  {
    id: 4000003,
    label: "나뭇가지",
    type: "item",
    extra: [],
  },
  {
    id: 4000004,
    label: "물컹물컹한 액체",
    type: "item",
    extra: [],
  },
  {
    id: 4000005,
    label: "나뭇잎",
    type: "item",
    extra: [],
  },
  {
    id: 4000006,
    label: "옥토퍼스의 다리",
    type: "item",
    extra: [],
  },
  {
    id: 4000007,
    label: "이블아이의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000008,
    label: "죽은 자의 부적",
    type: "item",
    extra: [],
  },
  {
    id: 4000009,
    label: "파란버섯의 갓",
    type: "item",
    extra: [],
  },
  {
    id: 4000010,
    label: "슬라임의 방울",
    type: "item",
    extra: [],
  },
  {
    id: 4000011,
    label: "버섯의 포자",
    type: "item",
    extra: [],
  },
  {
    id: 4000012,
    label: "초록버섯의 갓",
    type: "item",
    extra: [],
  },
  {
    id: 4000013,
    label: "커즈아이의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000014,
    label: "드레이크의 머리뼈",
    type: "item",
    extra: [],
  },
  {
    id: 4000015,
    label: "뿔버섯의 갓",
    type: "item",
    extra: [],
  },
  {
    id: 4000016,
    label: "빨간 달팽이의 껍질",
    type: "item",
    extra: [],
  },
  {
    id: 4000017,
    label: "돼지의 머리",
    type: "item",
    extra: [],
  },
  {
    id: 4000018,
    label: "장작",
    type: "item",
    extra: [],
  },
  {
    id: 4000019,
    label: "달팽이의 껍질",
    type: "item",
    extra: [],
  },
  {
    id: 4000020,
    label: "와일드보어의 송곳니",
    type: "item",
    extra: [],
  },
  {
    id: 4000021,
    label: "동물의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000022,
    label: "스톤골렘의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000023,
    label: "콜드아이의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000024,
    label: "파이어보어의 송곳니",
    type: "item",
    extra: [],
  },
  {
    id: 4000025,
    label: "다크 스톤골렘의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000026,
    label: "루팡 인형",
    type: "item",
    extra: [],
  },
  {
    id: 4000027,
    label: "와일드카고의 눈동자",
    type: "item",
    extra: [],
  },
  {
    id: 4000028,
    label: "타우로마시스의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000029,
    label: "루팡의 바나나",
    type: "item",
    extra: [],
  },
  {
    id: 4000030,
    label: "용의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000031,
    label: "저주받은 인형",
    type: "item",
    extra: [],
  },
  {
    id: 4000032,
    label: "리게이터의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000033,
    label: "크로코의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000034,
    label: "주니어 네키의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000035,
    label: "식탁보",
    type: "item",
    extra: [],
  },
  {
    id: 4000036,
    label: "이상한 느낌의 약",
    type: "item",
    extra: [],
  },
  {
    id: 4000037,
    label: "버블링의 큰 방울",
    type: "item",
    extra: [],
  },
  {
    id: 4000039,
    label: "아이언 호그의 철발굽",
    type: "item",
    extra: [],
  },
  {
    id: 4000040,
    label: "머쉬맘의 포자",
    type: "item",
    extra: [],
  },
  {
    id: 4000041,
    label: "멜러디의 실험용 개구리",
    type: "item",
    extra: [],
  },
  {
    id: 4000042,
    label: "스티지의 날개",
    type: "item",
    extra: [],
  },
  {
    id: 4000043,
    label: "로랑의 집게발",
    type: "item",
    extra: [],
  },
  {
    id: 4000044,
    label: "클랑의 집게발",
    type: "item",
    extra: [],
  },
  {
    id: 4000045,
    label: "엄티의 등껍질",
    type: "item",
    extra: [],
  },
  {
    id: 4000046,
    label: "타우로스피어의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000048,
    label: "주니어 예티의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000049,
    label: "예티의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000050,
    label: "페페의 부리",
    type: "item",
    extra: [],
  },
  {
    id: 4000051,
    label: "헥터의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000052,
    label: "화이트팽의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000053,
    label: "웨어울프의 발톱",
    type: "item",
    extra: [],
  },
  {
    id: 4000054,
    label: "라이칸스로프의 발톱",
    type: "item",
    extra: [],
  },
  {
    id: 4000055,
    label: "다크 주니어 예티의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000056,
    label: "다크 예티의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000057,
    label: "다크 페페의 부리",
    type: "item",
    extra: [],
  },
  {
    id: 4000058,
    label: "네펜데스의 씨앗",
    type: "item",
    extra: [],
  },
  {
    id: 4000059,
    label: "스타픽시의 별조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000060,
    label: "루나픽시의 달조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000061,
    label: "러스터픽시의 태양조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000062,
    label: "다크 네펜데스의 씨앗",
    type: "item",
    extra: [],
  },
  {
    id: 4000063,
    label: "스톤볼의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000067,
    label: "주니어 부기의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000068,
    label: "페어리의 더듬이",
    type: "item",
    extra: [],
  },
  {
    id: 4000069,
    label: "좀비의 잃어버린 어금니",
    type: "item",
    extra: [],
  },
  {
    id: 4000070,
    label: "샐리온의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000071,
    label: "라이오너의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000072,
    label: "그류핀의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000073,
    label: "단단한 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000074,
    label: "루이넬의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000076,
    label: "플라이아이의 날개",
    type: "item",
    extra: [],
  },
  {
    id: 4000078,
    label: "주니어 불독의 송곳니",
    type: "item",
    extra: [],
  },
  {
    id: 4000079,
    label: "불독의 송곳니",
    type: "item",
    extra: [],
  },
  {
    id: 4000080,
    label: "파이어독의 목걸이",
    type: "item",
    extra: [],
  },
  {
    id: 4000081,
    label: "파이어봄의 불씨",
    type: "item",
    extra: [],
  },
  {
    id: 4000082,
    label: "좀비의 잃어버린 금니",
    type: "item",
    extra: [],
  },
  {
    id: 4000083,
    label: "주니어 스톤볼의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000084,
    label: "아이스 스톤볼의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000085,
    label: "파이어 스톤볼의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000086,
    label: "리티의 털뭉치",
    type: "item",
    extra: [],
  },
  {
    id: 4000087,
    label: "다크 리티의 털뭉치",
    type: "item",
    extra: [],
  },
  {
    id: 4000088,
    label: "주니어 페페의 물고기",
    type: "item",
    extra: [],
  },
  {
    id: 4000095,
    label: "쥐덫",
    type: "item",
    extra: [],
  },
  {
    id: 4000096,
    label: "단단한 호두",
    type: "item",
    extra: [],
  },
  {
    id: 4000097,
    label: "거미줄",
    type: "item",
    extra: [],
  },
  {
    id: 4000098,
    label: "끈적이는 거미줄",
    type: "item",
    extra: [],
  },
  {
    id: 4000099,
    label: "문어 열쇠고리",
    type: "item",
    extra: [],
  },
  {
    id: 4000100,
    label: "플라스틱 왕관",
    type: "item",
    extra: [],
  },
  {
    id: 4000101,
    label: "노란색 장난감 블록",
    type: "item",
    extra: [],
  },
  {
    id: 4000102,
    label: "파란색 장난감 블록",
    type: "item",
    extra: [],
  },
  {
    id: 4000103,
    label: "비행기 프로펠러",
    type: "item",
    extra: [],
  },
  {
    id: 4000104,
    label: "비행기 모터",
    type: "item",
    extra: [],
  },
  {
    id: 4000105,
    label: "비행기 컨트롤러",
    type: "item",
    extra: [],
  },
  {
    id: 4000106,
    label: "곰인형의 솜뭉치",
    type: "item",
    extra: [],
  },
  {
    id: 4000107,
    label: "곰인형의 노란리본",
    type: "item",
    extra: [],
  },
  {
    id: 4000108,
    label: "팬더인형",
    type: "item",
    extra: [],
  },
  {
    id: 4000109,
    label: "장난감 새끼오리",
    type: "item",
    extra: [],
  },
  {
    id: 4000110,
    label: "장난감 병정의 칼",
    type: "item",
    extra: [],
  },
  {
    id: 4000111,
    label: "싸구려 건전지",
    type: "item",
    extra: [],
  },
  {
    id: 4000112,
    label: "기계심장",
    type: "item",
    extra: [],
  },
  {
    id: 4000113,
    label: "시계 태엽",
    type: "item",
    extra: [],
  },
  {
    id: 4000114,
    label: "작은 시계",
    type: "item",
    extra: [],
  },
  {
    id: 4000115,
    label: "톱니바퀴",
    type: "item",
    extra: [],
  },
  {
    id: 4000116,
    label: "작은 알",
    type: "item",
    extra: [],
  },
  {
    id: 4000117,
    label: "우주 식량",
    type: "item",
    extra: [],
  },
  {
    id: 4000118,
    label: "소형 우주선",
    type: "item",
    extra: [],
  },
  {
    id: 4000119,
    label: "수신기",
    type: "item",
    extra: [],
  },
  {
    id: 4000120,
    label: "마티안의 촉수",
    type: "item",
    extra: [],
  },
  {
    id: 4000121,
    label: "플라티안의 헬멧",
    type: "item",
    extra: [],
  },
  {
    id: 4000122,
    label: "메카티안의 광선총",
    type: "item",
    extra: [],
  },
  {
    id: 4000123,
    label: "낡은 고글",
    type: "item",
    extra: [],
  },
  {
    id: 4000124,
    label: "롬바드의 메모리카드",
    type: "item",
    extra: [],
  },
  {
    id: 4000125,
    label: "원로 그레이의 표식",
    type: "item",
    extra: [],
  },
  {
    id: 4000126,
    label: "MT-09의 연료",
    type: "item",
    extra: [],
  },
  {
    id: 4000127,
    label: "장난감 북",
    type: "item",
    extra: [],
  },
  {
    id: 4000128,
    label: "버피의 모자",
    type: "item",
    extra: [],
  },
  {
    id: 4000129,
    label: "레이지 버피의 구슬",
    type: "item",
    extra: [],
  },
  {
    id: 4000130,
    label: "버푼의 괘종시계",
    type: "item",
    extra: [],
  },
  {
    id: 4000131,
    label: "딥 버푼의 돌조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000132,
    label: "파이렛의 키",
    type: "item",
    extra: [],
  },
  {
    id: 4000133,
    label: "듀얼 파이렛의 추진기",
    type: "item",
    extra: [],
  },
  {
    id: 4000134,
    label: "바이킹의 돛",
    type: "item",
    extra: [],
  },
  {
    id: 4000135,
    label: "기간틱 바이킹의 모자",
    type: "item",
    extra: [],
  },
  {
    id: 4000143,
    label: "좀비테니 인형",
    type: "item",
    extra: [],
  },
  {
    id: 4000144,
    label: "자유로운 영혼",
    type: "item",
    extra: [],
  },
  {
    id: 4000145,
    label: "봉인된 괘종시계",
    type: "item",
    extra: [],
  },
  {
    id: 4000146,
    label: "사악한 영혼",
    type: "item",
    extra: [],
  },
  {
    id: 4000147,
    label: "봉인된 테니인형",
    type: "item",
    extra: [],
  },
  {
    id: 4000148,
    label: "속박의 굴레",
    type: "item",
    extra: [],
  },
  {
    id: 4000149,
    label: "봉인된 항아리",
    type: "item",
    extra: [],
  },
  {
    id: 4000150,
    label: "팬텀워치의 얼음조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000151,
    label: "게이트키퍼의 완장",
    type: "item",
    extra: [],
  },
  {
    id: 4000152,
    label: "타나토스의 견장",
    type: "item",
    extra: [],
  },
  {
    id: 4000153,
    label: "스노클",
    type: "item",
    extra: [],
  },
  {
    id: 4000154,
    label: "아기 물개 인형",
    type: "item",
    extra: [],
  },
  {
    id: 4000155,
    label: "물개가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000156,
    label: "물개의 송곳니",
    type: "item",
    extra: [],
  },
  {
    id: 4000157,
    label: "물개고기",
    type: "item",
    extra: [],
  },
  {
    id: 4000158,
    label: "푸퍼의 알",
    type: "item",
    extra: [],
  },
  {
    id: 4000159,
    label: "포이즌 푸퍼의 독가시",
    type: "item",
    extra: [],
  },
  {
    id: 4000160,
    label: "바늘",
    type: "item",
    extra: [],
  },
  {
    id: 4000161,
    label: "해마의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000162,
    label: "화려한 비늘가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000163,
    label: "해마의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000164,
    label: "버블피쉬의 마음",
    type: "item",
    extra: [],
  },
  {
    id: 4000165,
    label: "화려한 꽃잎",
    type: "item",
    extra: [],
  },
  {
    id: 4000166,
    label: "새우살",
    type: "item",
    extra: [],
  },
  {
    id: 4000167,
    label: "딱딱한 비늘",
    type: "item",
    extra: [],
  },
  {
    id: 4000168,
    label: "해바라기씨",
    type: "item",
    extra: [],
  },
  {
    id: 4000169,
    label: "절구공이",
    type: "item",
    extra: [],
  },
  {
    id: 4000170,
    label: "호랑이 발도장",
    type: "item",
    extra: [],
  },
  {
    id: 4000171,
    label: "호랑이 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4000172,
    label: "삼미호의 꼬리",
    type: "item",
    extra: [],
  },
  {
    id: 4000173,
    label: "빗자루",
    type: "item",
    extra: [],
  },
  {
    id: 4000175,
    label: "피아누스 미니어쳐",
    type: "item",
    extra: [],
  },
  {
    id: 4000176,
    label: "독버섯",
    type: "item",
    extra: [],
  },
  {
    id: 4000177,
    label: "믹스블록",
    type: "item",
    extra: [],
  },
  {
    id: 4000178,
    label: "아이언보어의 갑옷조각",
    type: "item",
    extra: [],
  },
  {
    id: 4000179,
    label: "망둥이 묶음",
    type: "item",
    extra: [],
  },
  {
    id: 4000180,
    label: "상어 틀니",
    type: "item",
    extra: [],
  },
  {
    id: 4000181,
    label: "냉동포장 샥스핀",
    type: "item",
    extra: [],
  },
  {
    id: 4000182,
    label: "석회가루병",
    type: "item",
    extra: [],
  },
  {
    id: 4000183,
    label: "먹물병",
    type: "item",
    extra: [],
  },
  {
    id: 4000184,
    label: "전기버터구이",
    type: "item",
    extra: [],
  },
  {
    id: 4000185,
    label: "얼음 등뼈",
    type: "item",
    extra: [],
  },
  {
    id: 4000186,
    label: "다크 드레이크의 뿔",
    type: "item",
    extra: [],
  },
  {
    id: 4000195,
    label: "묘목",
    type: "item",
    extra: [],
  },
  {
    id: 4000196,
    label: "목판",
    type: "item",
    extra: [],
  },
  {
    id: 4000197,
    label: "석판",
    type: "item",
    extra: [],
  },
  {
    id: 4000204,
    label: "스켈독의 뼈",
    type: "item",
    extra: [],
  },
  {
    id: 4000205,
    label: "더러운 붕대",
    type: "item",
    extra: [],
  },
  {
    id: 4000206,
    label: "갈비뼈",
    type: "item",
    extra: [],
  },
  {
    id: 4000207,
    label: "골반뼈",
    type: "item",
    extra: [],
  },
  {
    id: 4000208,
    label: "말 머리뼈",
    type: "item",
    extra: [],
  },
  {
    id: 4001000,
    label: "아르웬의 유리구두",
    type: "item",
    extra: [],
  },
  {
    id: 4001004,
    label: "'돼지와 함께 춤을'의 땅문서",
    type: "item",
    extra: [],
  },
  {
    id: 4001005,
    label: "고대의 주문서",
    type: "item",
    extra: [],
  },
  {
    id: 4001006,
    label: "불꽃 깃털",
    type: "item",
    extra: [],
  },
  {
    id: 4001084,
    label: "파풀라투스의 증표",
    type: "item",
    extra: [],
  },
  {
    id: 4001105,
    label: "질긴 용의 가죽",
    type: "item",
    extra: [],
  },
  {
    id: 4003002,
    label: "얼음 조각",
    type: "item",
    extra: [],
  },
  {
    id: 4003004,
    label: "뻣뻣한 깃털",
    type: "item",
    extra: [],
  },
  {
    id: 4003005,
    label: "부드러운 깃털",
    type: "item",
    extra: [],
  },
  {
    id: 4004000,
    label: "힘의 크리스탈 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4004001,
    label: "지혜의 크리스탈 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4004002,
    label: "민첩성의 크리스탈 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4004003,
    label: "행운의 크리스탈 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4004004,
    label: "어둠의 크리스탈 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4005002,
    label: "민첩성의 크리스탈",
    type: "item",
    extra: [],
  },
  {
    id: 4006000,
    label: "마법의 돌",
    type: "item",
    extra: [],
  },
  {
    id: 4006001,
    label: "소환의 돌",
    type: "item",
    extra: [],
  },
  {
    id: 4010000,
    label: "청동의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4010001,
    label: "강철의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4010002,
    label: "미스릴의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4010003,
    label: "아다만티움의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4010004,
    label: "은의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4010005,
    label: "오리할콘의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4010006,
    label: "금의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4011007,
    label: "달의 돌",
    type: "item",
    extra: [],
  },
  {
    id: 4020000,
    label: "가넷의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020001,
    label: "자수정의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020002,
    label: "아쿠아마린의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020003,
    label: "에메랄드의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020004,
    label: "오팔의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020005,
    label: "사파이어의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020006,
    label: "토파즈의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020007,
    label: "다이아몬드의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4020008,
    label: "흑수정의 원석",
    type: "item",
    extra: [],
  },
  {
    id: 4021008,
    label: "흑수정",
    type: "item",
    extra: [],
  },
  {
    id: 4021009,
    label: "별의 돌",
    type: "item",
    extra: [],
  },
  {
    id: 4030000,
    label: "슬라임 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030001,
    label: "버섯 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030009,
    label: "바둑판",
    type: "item",
    extra: [],
  },
  {
    id: 4030010,
    label: "옥토퍼스 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030011,
    label: "돼지 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030012,
    label: "몬스터 카드",
    type: "item",
    extra: [],
  },
  {
    id: 4030013,
    label: "블록퍼스 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030014,
    label: "핑크 테니 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030015,
    label: "팬더 테니 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4030016,
    label: "트릭스터 오목알",
    type: "item",
    extra: [],
  },
  {
    id: 4031072,
    label: "매직박스의 열쇠",
    type: "item",
    extra: [],
  },
  {
    id: 4031089,
    label: "드라이버",
    type: "item",
    extra: [],
  },
  {
    id: 4031090,
    label: "기억의 조각",
    type: "item",
    extra: [],
  },
  {
    id: 4031093,
    label: "맛있는 호두",
    type: "item",
    extra: [],
  },
  {
    id: 4031098,
    label: "만능 시계 태엽",
    type: "item",
    extra: [],
  },
  {
    id: 4031102,
    label: "노트",
    type: "item",
    extra: [],
  },
  {
    id: 4031103,
    label: "바나드 그레이의 세포",
    type: "item",
    extra: [],
  },
  {
    id: 4031104,
    label: "제타 그레이의 세포",
    type: "item",
    extra: [],
  },
  {
    id: 4031105,
    label: "울트라 그레이의 세포",
    type: "item",
    extra: [],
  },
  {
    id: 4031106,
    label: "원로 그레이의 세포",
    type: "item",
    extra: [],
  },
  {
    id: 4031115,
    label: "특수 건전지",
    type: "item",
    extra: [],
  },
  {
    id: 4031129,
    label: "고소한 치즈",
    type: "item",
    extra: [],
  },
  {
    id: 4031140,
    label: "빛을 잃은 크리스탈",
    type: "item",
    extra: [],
  },
  {
    id: 4031146,
    label: "동물의 뼈화석",
    type: "item",
    extra: [],
  },
  {
    id: 4031147,
    label: "식물의 나뭇잎화석",
    type: "item",
    extra: [],
  },
  {
    id: 4031153,
    label: "스텀프의 눈물",
    type: "item",
    extra: [],
  },
  {
    id: 4031155,
    label: "깨진 거울조각",
    type: "item",
    extra: [],
  },
  {
    id: 4031159,
    label: "메이플 고서 下",
    type: "item",
    extra: [],
  },
  {
    id: 4031164,
    label: "악어가죽 주머니",
    type: "item",
    extra: [],
  },
  {
    id: 4031171,
    label: "신비한 가루",
    type: "item",
    extra: [],
  },
  {
    id: 4031172,
    label: "루디브리엄의 메달",
    type: "item",
    extra: [],
  },
  {
    id: 4031175,
    label: "타키온",
    type: "item",
    extra: [],
  },
  {
    id: 4031176,
    label: "차원의 균열 조각 A",
    type: "item",
    extra: [],
  },
  {
    id: 4031177,
    label: "차원의 균열 조각 B",
    type: "item",
    extra: [],
  },
  {
    id: 4031178,
    label: "차원의 균열 조각 C",
    type: "item",
    extra: [],
  },
  {
    id: 4031193,
    label: "영혼수집기",
    type: "item",
    extra: [],
  },
  {
    id: 4031195,
    label: "오로라 구슬",
    type: "item",
    extra: [],
  },
  {
    id: 4031206,
    label: "찢어진 여행 티켓1",
    type: "item",
    extra: [],
  },
  {
    id: 4031209,
    label: "SOS구조 요청 편지",
    type: "item",
    extra: [],
  },
  {
    id: 4031212,
    label: "싸늘한 기운",
    type: "item",
    extra: [],
  },
  {
    id: 4031213,
    label: "와일드카고의 영혼석",
    type: "item",
    extra: [],
  },
  {
    id: 4031214,
    label: "타우로마시스의 영혼석",
    type: "item",
    extra: [],
  },
  {
    id: 4031215,
    label: "타우로스피어의 영혼석",
    type: "item",
    extra: [],
  },
  {
    id: 4031218,
    label: "어둠의 계약서",
    type: "item",
    extra: [],
  },
  {
    id: 4031222,
    label: "무쇠 호미",
    type: "item",
    extra: [],
  },
  {
    id: 4031232,
    label: "도깨비 감투",
    type: "item",
    extra: [],
  },
  {
    id: 4031233,
    label: "도깨비 방망이",
    type: "item",
    extra: [],
  },
  {
    id: 4031234,
    label: "도깨비 망토",
    type: "item",
    extra: [],
  },
  {
    id: 4031239,
    label: "카밀라에게 보내는 편지",
    type: "item",
    extra: [],
  },
  {
    id: 4031241,
    label: "제비가 잃어버린 박씨",
    type: "item",
    extra: [],
  },
  {
    id: 4031251,
    label: "심해먼지",
    type: "item",
    extra: [],
  },
  {
    id: 4031252,
    label: "찢어진 노트",
    type: "item",
    extra: [],
  },
  {
    id: 4031253,
    label: "피아누스의 비명소리",
    type: "item",
    extra: [],
  },
  {
    id: 4031254,
    label: "망가진 손전등",
    type: "item",
    extra: [],
  },
  {
    id: 4031255,
    label: "고장난 카메라",
    type: "item",
    extra: [],
  },
  {
    id: 4031256,
    label: "차가운 눈물",
    type: "item",
    extra: [],
  },
  {
    id: 4031259,
    label: "씨클의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031260,
    label: "씨코의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031261,
    label: "핀붐의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031262,
    label: "플라워피쉬의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031263,
    label: "마스크피쉬의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031264,
    label: "버블피쉬의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031265,
    label: "푸퍼의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031266,
    label: "스파커의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031267,
    label: "프리져의 세포샘플",
    type: "item",
    extra: [],
  },
  {
    id: 4031268,
    label: "튼튼한 동아줄",
    type: "item",
    extra: [],
  },
  {
    id: 4031269,
    label: "미끌미끌한 기름",
    type: "item",
    extra: [],
  },
  {
    id: 4031273,
    label: "빨간 털실뭉치",
    type: "item",
    extra: [],
  },
  {
    id: 4031279,
    label: "정부미",
    type: "item",
    extra: [],
  },
  {
    id: 4031309,
    label: "마법 구름 조각",
    type: "item",
    extra: [],
  },
  {
    id: 4031311,
    label: "눈송이 결정",
    type: "item",
    extra: [],
  },
  {
    id: 4031458,
    label: "타나토스의 검은 돌풍",
    type: "item",
    extra: [],
  },
  {
    id: 4031474,
    label: "영혼의 계약서",
    type: "item",
    extra: [],
  },
  {
    id: 4031477,
    label: "라이칸스로프의 계획서",
    type: "item",
    extra: [],
  },
  {
    id: 4130001,
    label: "신발 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130002,
    label: "한손검 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130003,
    label: "한손도끼 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130004,
    label: "한손둔기 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130005,
    label: "두손검 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130006,
    label: "두손도끼 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130007,
    label: "두손둔기 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130008,
    label: "창 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130009,
    label: "폴암 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130010,
    label: "완드 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130011,
    label: "스태프 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130012,
    label: "활 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130013,
    label: "석궁 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130014,
    label: "단검 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4130015,
    label: "아대 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 4131000,
    label: "한손검 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131001,
    label: "한손도끼 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131002,
    label: "한손둔기 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131003,
    label: "두손검 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131004,
    label: "두손도끼 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131005,
    label: "두손둔기 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131006,
    label: "창 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131007,
    label: "폴암 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131008,
    label: "완드 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131009,
    label: "스태프 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131010,
    label: "활 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131011,
    label: "석궁 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131012,
    label: "단검 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 4131013,
    label: "아대 제조법",
    type: "item",
    extra: [],
  },
  {
    id: 2000000,
    label: "빨간 포션",
    type: "item",
    extra: [],
  },
  {
    id: 2000001,
    label: "주황 포션",
    type: "item",
    extra: [],
  },
  {
    id: 2000002,
    label: "하얀 포션",
    type: "item",
    extra: [],
  },
  {
    id: 2000003,
    label: "파란 포션",
    type: "item",
    extra: [],
  },
  {
    id: 2000004,
    label: "엘릭서",
    type: "item",
    extra: [],
  },
  {
    id: 2000005,
    label: "파워 엘릭서",
    type: "item",
    extra: [],
  },
  {
    id: 2000006,
    label: "마나 엘릭서",
    type: "item",
    extra: [],
  },
  {
    id: 2002000,
    label: "민첩함의 물약",
    type: "item",
    extra: [],
  },
  {
    id: 2002001,
    label: "속도향상의 물약",
    type: "item",
    extra: [],
  },
  {
    id: 2002002,
    label: "마법사의 물약",
    type: "item",
    extra: [],
  },
  {
    id: 2002003,
    label: "현자의 물약",
    type: "item",
    extra: [],
  },
  {
    id: 2002004,
    label: "전사의 물약",
    type: "item",
    extra: [],
  },
  {
    id: 2002005,
    label: "명사수의 물약",
    type: "item",
    extra: [],
  },
  {
    id: 2002006,
    label: "전사의 알약",
    type: "item",
    extra: [],
  },
  {
    id: 2002007,
    label: "마법사의 알약",
    type: "item",
    extra: [],
  },
  {
    id: 2002008,
    label: "명사수의 알약",
    type: "item",
    extra: [],
  },
  {
    id: 2002009,
    label: "민첩함의 알약",
    type: "item",
    extra: [],
  },
  {
    id: 2002010,
    label: "속도 향상의 알약",
    type: "item",
    extra: [],
  },
  {
    id: 2010003,
    label: "오렌지",
    type: "item",
    extra: [],
  },
  {
    id: 2010004,
    label: "레몬",
    type: "item",
    extra: [],
  },
  {
    id: 2012000,
    label: "드레이크의 피",
    type: "item",
    extra: [],
  },
  {
    id: 2012002,
    label: "고목 나무의 수액",
    type: "item",
    extra: [],
  },
  {
    id: 2012003,
    label: "드레이크의 고기",
    type: "item",
    extra: [],
  },
  {
    id: 2020013,
    label: "순록의 우유",
    type: "item",
    extra: [],
  },
  {
    id: 2020014,
    label: "새벽의 이슬",
    type: "item",
    extra: [],
  },
  {
    id: 2020015,
    label: "황혼의 이슬",
    type: "item",
    extra: [],
  },
  {
    id: 2022003,
    label: "장어 구이",
    type: "item",
    extra: [],
  },
  {
    id: 2022039,
    label: "네펜데스의 꿀",
    type: "item",
    extra: [],
  },
  {
    id: 2022040,
    label: "공기방울",
    type: "item",
    extra: [],
  },
  {
    id: 2050000,
    label: "해독제",
    type: "item",
    extra: [],
  },
  {
    id: 2050001,
    label: "안약",
    type: "item",
    extra: [],
  },
  {
    id: 2050002,
    label: "보약",
    type: "item",
    extra: [],
  },
  {
    id: 2050003,
    label: "성수",
    type: "item",
    extra: [],
  },
  {
    id: 2050004,
    label: "만병통치약",
    type: "item",
    extra: [],
  },
  {
    id: 2060000,
    label: "활전용 화살",
    type: "item",
    extra: [],
  },
  {
    id: 2060001,
    label: "활전용 청동화살",
    type: "item",
    extra: [],
  },
  {
    id: 2061000,
    label: "석궁전용 화살",
    type: "item",
    extra: [],
  },
  {
    id: 2061001,
    label: "석궁전용 청동화살",
    type: "item",
    extra: [],
  },
  {
    id: 2070000,
    label: "수비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070001,
    label: "월비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070002,
    label: "목비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070003,
    label: "금비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070004,
    label: "토비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070005,
    label: "뇌전 수리검",
    type: "item",
    extra: [],
  },
  {
    id: 2070006,
    label: "일비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070007,
    label: "화비 표창",
    type: "item",
    extra: [],
  },
  {
    id: 2070008,
    label: "눈덩이",
    type: "item",
    extra: [],
  },
  {
    id: 2070009,
    label: "나무팽이",
    type: "item",
    extra: [],
  },
  {
    id: 2070010,
    label: "고드름",
    type: "item",
    extra: [],
  },
  {
    id: 2048001,
    label: "펫장비 이동속도 주문서 60%",
    type: "item",
    extra: ["펫이속"],
  },
  {
    id: 2041016,
    label: "망토 지력 주문서 60%",
    type: "item",
    extra: ["망지"],
  },
  {
    id: 2040502,
    label: "전신 갑옷 민첩 주문서 10%",
    type: "item",
    extra: ["전민"],
  },
  {
    id: 2040402,
    label: "상의 방어력 주문서 10%",
    type: "item",
    extra: ["상방"],
  },
  {
    id: 2044301,
    label: "창 공격력 주문서 60%",
    type: "item",
    extra: ["창공"],
  },
  {
    id: 2041010,
    label: "망토 마나 주문서 60%",
    type: "item",
    extra: ["망마나"],
  },
  {
    id: 2041020,
    label: "망토 민첩 주문서 10%",
    type: "item",
    extra: ["망민"],
  },
  {
    id: 2040901,
    label: "방패 방어력 주문서 60%",
    type: "item",
    extra: ["방방"],
  },
  {
    id: 2041002,
    label: "망토 방어력 주문서 10%",
    type: "item",
    extra: ["망방"],
  },
  {
    id: 2041017,
    label: "망토 지력 주문서 10%",
    type: "item",
    extra: ["망지"],
  },
  {
    id: 2041004,
    label: "망토 방어력 주문서 60%",
    type: "item",
    extra: ["망방"],
  },
  {
    id: 2040701,
    label: "신발 회피 주문서 60%",
    type: "item",
    extra: ["신회"],
  },
  {
    id: 2043801,
    label: "스태프 마력 주문서 60%",
    type: "item",
    extra: ["스마"],
  },
  {
    id: 2041015,
    label: "망토 지력 주문서 100%",
    type: "item",
    extra: ["망지"],
  },
  {
    id: 4130000,
    label: "장갑 제작의 촉진제",
    type: "item",
    extra: [],
  },
  {
    id: 2044601,
    label: "석궁 공격력 주문서 60%",
    type: "item",
    extra: ["석공"],
  },
  {
    id: 2040704,
    label: "신발 점프력 주문서 60%",
    type: "item",
    extra: ["신점프"],
  },
  {
    id: 2040513,
    label: "전신 갑옷 지력 주문서 60%",
    type: "item",
    extra: ["전지"],
  },
  {
    id: 2043001,
    label: "한손검 공격력 주문서 60%",
    type: "item",
    extra: ["한공"],
  },
  {
    id: 2040804,
    label: "장갑 공격력 주문서 60%",
    type: "item",
    extra: ["장공", "공노목"],
  },
  {
    id: 2048005,
    label: "펫장비 점프력 주문서 10%",
    type: "item",
    extra: ["펫점프"],
  },
  {
    id: 2041023,
    label: "망토 행운 주문서 10%",
    type: "item",
    extra: ["망행"],
  },
  {
    id: 2040601,
    label: "하의 방어력 주문서 60%",
    type: "item",
    extra: ["하방"],
  },
  {
    id: 2048003,
    label: "펫장비 점프력 주문서 100%",
    type: "item",
    extra: ["펫점프"],
  },
  {
    id: 2041013,
    label: "망토 힘 주문서 60%",
    type: "item",
    extra: ["망힘"],
  },
  {
    id: 2048000,
    label: "펫장비 이동속도 주문서 100%",
    type: "item",
    extra: ["펫이속"],
  },
  {
    id: 2041006,
    label: "망토 체력 주문서 100%",
    type: "item",
    extra: ["망체"],
  },
  {
    id: 2040707,
    label: "신발 이동속도 주문서 60%",
    type: "item",
    extra: ["신이속"],
  },
  {
    id: 2044702,
    label: "아대 공격력 주문서 10%",
    type: "item",
    extra: ["아공"],
  },
  {
    id: 2041007,
    label: "망토 체력 주문서 60%",
    type: "item",
    extra: ["망체"],
  },
  {
    id: 2044501,
    label: "활 공격력 주문서 60%",
    type: "item",
    extra: ["활공"],
  },
  {
    id: 2043802,
    label: "스태프 마력 주문서 10%",
    type: "item",
    extra: ["스마"],
  },
  {
    id: 2041005,
    label: "망토 방어력 주문서 10%",
    type: "item",
    extra: ["망방"],
  },
  {
    id: 2043301,
    label: "단검 공격력 주문서 60%",
    type: "item",
    extra: ["단공"],
  },
  {
    id: 2040703,
    label: "신발 점프력 주문서 100%",
    type: "item",
    extra: ["신점"],
  },
  {
    id: 2040708,
    label: "신발 이동속도 주문서 10%",
    type: "item",
    extra: ["신이속"],
  },
  {
    id: 2043002,
    label: "한손검 공격력 주문서 10%",
    type: "item",
    extra: ["한공"],
  },
  {
    id: 2043102,
    label: "한손도끼 공격력 주문서 10%",
    type: "item",
    extra: ["한공"],
  },
  {
    id: 2044701,
    label: "아대 공격력 주문서 60%",
    type: "item",
    extra: ["아공"],
  },
  {
    id: 2040602,
    label: "하의 방어력 주문서 10%",
    type: "item",
    extra: ["하방"],
  },
  {
    id: 2040002,
    label: "투구 방어력 주문서 10%",
    type: "item",
    extra: ["투방"],
  },
  {
    id: 2040705,
    label: "신발 점프력 주문서 10%",
    type: "item",
    extra: ["신점"],
  },
  {
    id: 2043101,
    label: "한손도끼 공격력 주문서 60%",
    type: "item",
    extra: ["창공"],
  },
  {
    id: 2044002,
    label: "두손검 공격력 주문서 10%",
    type: "item",
    extra: ["두공"],
  },
  {
    id: 2048002,
    label: "펫장비 이동속도 주문서 10%",
    type: "item",
    extra: ["펫이속"],
  },
  {
    id: 2040805,
    label: "장갑 공격력 주문서 10%",
    type: "item",
    extra: ["장공", "공노목"],
  },
  {
    id: 2040702,
    label: "신발 회피 주문서 10%",
    type: "item",
    extra: ["신회"],
  },
  {
    id: 2040505,
    label: "전신 갑옷 방어력 주문서 10%",
    type: "item",
    extra: ["전방"],
  },
  {
    id: 2040004,
    label: "투구 체력 주문서 60%",
    type: "item",
    extra: ["투체"],
  },
  {
    id: 2040401,
    label: "상의 방어력 주문서 60%",
    type: "item",
    extra: ["상방"],
  },
  {
    id: 2041012,
    label: "망토 힘 주문서 100%",
    type: "item",
    extra: ["망힘"],
  },
  {
    id: 2044201,
    label: "두손둔기 공격력 주문서 60%",
    type: "item",
    extra: ["두공"],
  },
  {
    id: 2043701,
    label: "완드 마력 주문서 60%",
    type: "item",
    extra: ["완마"],
  },
  {
    id: 1060007,
    label: "파란 칠부바지",
    type: "item",
    extra: [],
  },
  {
    id: 2041008,
    label: "망토 체력 주문서 10%",
    type: "item",
    extra: ["망체"],
  },
  {
    id: 2041021,
    label: "망토 행운 주문서 100%",
    type: "item",
    extra: ["망행"],
  },
  {
    id: 2041014,
    label: "망토 힘 주문서 10%",
    type: "item",
    extra: ["망힘"],
  },
  {
    id: 2048004,
    label: "펫장비 점프력 주문서 60%",
    type: "item",
    extra: ["펫점"],
  },
  {
    id: 2044502,
    label: "활 공격력 주문서 10%",
    type: "item",
    extra: ["활공"],
  },
  {
    id: 2041018,
    label: "망토 민첩 주문서 100%",
    type: "item",
    extra: ["망민"],
  },
  {
    id: 2044101,
    label: "두손도끼 공격력 주문서 60%",
    type: "item",
    extra: ["두공"],
  },
  {
    id: 2044401,
    label: "폴암 공격력 주문서 60%",
    type: "item",
    extra: ["폴공"],
  },
  {
    id: 2043202,
    label: "한손둔기 공격력 주문서 10%",
    type: "item",
    extra: ["한공"],
  },
  {
    id: 2040001,
    label: "투구 방어력 주문서 60%",
    type: "item",
    extra: ["투방"],
  },
  {
    id: 2044602,
    label: "석궁 공격력 주문서 10%",
    type: "item",
    extra: ["석공"],
  },
  {
    id: 2041001,
    label: "망토 방어력 주문서 60%",
    type: "item",
    extra: ["망방"],
  },
  {
    id: 2041011,
    label: "망토 마나 주문서 10%",
    type: "item",
    extra: ["망마"],
  },
  {
    id: 2040302,
    label: "귀 장식 지력 주문서 10%",
    type: "item",
    extra: ["귀지"],
  },
  {
    id: 2040900,
    label: "방패 방어력 주문서 100%",
    type: "item",
    extra: ["방방"],
  },
  {
    id: 2041022,
    label: "망토 행운 주문서 60%",
    type: "item",
    extra: ["망행"],
  },
  {
    id: 2040504,
    label: "전신 갑옷 방어력 주문서 60%",
    type: "item",
    extra: ["전방"],
  },
  {
    id: 2040802,
    label: "장갑 민첩 주문서 10%",
    type: "item",
    extra: ["장민"],
  },
  {
    id: 2040005,
    label: "투구 체력 주문서 10%",
    type: "item",
    extra: ["투체"],
  },
  {
    id: 2040301,
    label: "귀 장식 지력 주문서 60%",
    type: "item",
    extra: ["귀지"],
  },
  {
    id: 2040516,
    label: "전신 갑옷 행운 주문서 60%",
    type: "item",
    extra: ["전행"],
  },
  {
    id: 2043201,
    label: "한손둔기 공격력 주문서 60%",
    type: "item",
    extra: ["한공"],
  },
  {
    id: 2040902,
    label: "방패 방어력 주문서 10%",
    type: "item",
    extra: ["방방"],
  },
  {
    id: 2044102,
    label: "두손도끼 공격력 주문서 10%",
    type: "item",
    extra: ["두공"],
  },
  {
    id: 2040801,
    label: "장갑 민첩 주문서 60%",
    type: "item",
    extra: ["장민"],
  },
  {
    id: 2040501,
    label: "전신 갑옷 민첩 주문서 60%",
    type: "item",
    extra: ["전민"],
  },
  {
    id: 2044202,
    label: "두손둔기 공격력 주문서 10%",
    type: "item",
    extra: ["두공"],
  },
  {
    id: 2041019,
    label: "망토 민첩 주문서 60%",
    type: "item",
    extra: ["망민"],
  },
  {
    id: 2044402,
    label: "폴암 공격력 주문서 10%",
    type: "item",
    extra: ["폴공"],
  },
  {
    id: 2044001,
    label: "두손검 공격력 주문서 60%",
    type: "item",
    extra: ["두공"],
  },
  {
    id: 2043702,
    label: "완드 마력 주문서 10%",
    type: "item",
    extra: ["완마"],
  },
  {
    id: 2044302,
    label: "창 공격력 주문서 10%",
    type: "item",
    extra: ["창공"],
  },
  {
    id: 2041009,
    label: "망토 마나 주문서 100%",
    type: "item",
    extra: ["망지"],
  },
  {
    id: 2043302,
    label: "단검 공격력 주문서 10%",
    type: "item",
    extra: ["단공"],
  },
  {
    id: 1432002,
    label: "삼지창",
    type: "item",
    extra: [],
  },
];

export default Options;

