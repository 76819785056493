import React from "react";
import Home from "./Component/Home";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    if (window.location.host != "old-maple.kr") {
      window.location = "https://old-maple.kr";
    }
    let mode = window.localStorage.getItem("mode");
    if (!mode) {
      mode = "light";
    }

    this.state = {
      mode: mode,
      light: createTheme({
        palette: {
          mode: "light",
        },
      }),
      dark: createTheme({
        palette: {
          mode: "dark",
        },
      }),
    };
  }

  changeMode = () => {
    if (this.state.mode == "light") {
      this.setState({
        mode: "dark",
      });
      window.localStorage.setItem("mode", "dark");
    } else {
      this.setState({
        mode: "light",
      });
      window.localStorage.setItem("mode", "light");
    }
  };

  render() {
    return (
      <div className="App">
        <ThemeProvider theme={this.state[this.state.mode]}>
          <CssBaseline />
          {this.state.mode == "dark" ? (
            <Switch color="warning" onChange={this.changeMode} />
          ) : (
            <Switch defaultChecked color="warning" onChange={this.changeMode} />
          )}
          <div className="App-content">
            <Home></Home>

            <Box
              sx={{
                py: 3,
                px: 2,
                mt: "auto",
              }}
              component="footer"
            >
              <Container
                maxWidth="sm"
                sx={{ color: "text.secondary", fontSize: 10 }}
              >
                {"OSMLIB 및 GMS 62를 기준으로 제작되었습니다. | "}
                  <Link color="inherit" href="/donate.txt">
	            {"Donate"}
                  </Link>
                <br />
                <Typography
                  variant="body"
                  color="text.secondary"
                  align="center"
                  sx={{ fontSize: 10 }}
                >
                  {"Copyright © "}
                  <Link color="inherit" href="https://03sunf.com/">
                    03sunf
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Container>
            </Box>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;

