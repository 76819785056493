import React from "react";
import SearchBar from "./SearchBar";
import Result from "./Result";
import Typography from "@mui/material/Typography";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { matchedMobs: [], matchedItems: [] },
    };
  }

  search = (keyword) => {
    fetch("/api/search", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ keyword }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.setState({
          data: response,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h4" sx={{ marginBottom: 3 }}>
          <a href="/">
            <img src="./logo.png" height="200" />
          </a>
        </Typography>
        <SearchBar search={this.search} />
        <Result search={this.search} data={this.state.data} />
      </React.Fragment>
    );
  }
}

export default Home;

