import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Avatar,
  Chip,
} from "@mui/material";

class Result extends React.Component {
  constructor(props) {
    super(props);
  }

  itemVariance = (item, e) => {
    if (item.itemTypeInfo.overallCategory != "Equip" && !item.meta.equip.tuc)
      return "";
    const n = Math.min(Math.floor(e / 10 + 1), 5);
    const i = e - n;
    const o = e + n;
    return `(${i}~${o})`;
  };

  render() {
    if (this.props.data) {
      return (
        <React.Fragment>
          {this.props.data.matchedMobs.map((mob) => (
            <div
              key={mob.mobId}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px",
                color: "black",
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  width: "100%",
                  maxWidth: "75%",
                  margin: "auto",
                }}
              >
                <Table>
                  <TableHead sx={{ borderColor: "#FFFFFF" }}>
                    <TableRow>
                      <TableCell sx={{ width: 250 }}>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          몬스터
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          color="textSecondary"
                        ></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={mob.mobId}>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Grid container direction="column" alignItems="center">
                          <Avatar
                            alt={mob.korName}
                            sx={{
                              m: 1,
                              width: 150,
                              height: 150,
                              bgcolor: "#FAFAFA",
                            }}
                          >
                            <img
                              src={
                                "https://maplestory.io/api/gms/62/mob/" +
                                mob.mobId +
                                "/icon"
                              }
                              style={{ maxHeight: 100 }}
                              loading="lazy"
                            />
                          </Avatar>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="textPrimary"
                            sx={{ margin: 1 }}
                          >
                            {mob.korName}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {mob.description}
                          </Typography>
                          <div
                            style={{
                              marginTop: "8px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <Chip
                              label={
                                "HP " + mob.meta.maxHP.toLocaleString("ko-KR")
                              }
                              color="error"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                            <Chip
                              label={
                                "MP " + mob.meta.maxMP.toLocaleString("ko-KR")
                              }
                              color="primary"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                            <Chip
                              label={
                                "EXP " + mob.meta.exp.toLocaleString("ko-KR")
                              }
                              color="warning"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                            <Chip
                              label={
                                "물리방어력 " +
                                mob.meta.physicalDefense.toLocaleString("ko-KR")
                              }
                              color="default"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                            <Chip
                              label={
                                "마법방어력 " +
                                mob.meta.magicDefense.toLocaleString("ko-KR")
                              }
                              color="default"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                            <Chip
                              label={
                                "회피율 " +
                                mob.meta.evasion.toLocaleString("ko-KR")
                              }
                              color="default"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                            <Chip
                              label={
                                "권장 명중률 " +
                                Math.floor(
                                  mob.meta.evasion * 3.666
                                ).toLocaleString("ko-KR")
                              }
                              color="default"
                              sx={{
                                height: "1.5rem",
                                width: "16rem",
                                fontSize: "0.75rem",
                                borderRadius: "4px",
                              }}
                            ></Chip>
                          </div>
                        </Grid>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ width: 200, textAlign: "center" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  드롭 아이템
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  능력치 (옵션)
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  필요 능력치
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  드롭율
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mob.meta.drops.mesos.map((meso) => (
                              <TableRow key="meso">
                                <TableCell sx={{ textAlign: "center" }}>
                                  <img
                                    src={
                                      meso.amount < 50
                                        ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAwCAMAAABZu7juAAAAdVBMVEVMaXHdmWaqdzOZZjPMmUSqd0SIVTMAAAC7d0TMiETMiFW7iERVNCKZZkRmRCJyRDCIZkTuzJz//93dqnS7iFgiEQ1EIhnMmVUzHhF3VTeZd0zIt66qZjOqglVmMyLuu4D/7tW7d1X/3bvd3d3////dmVWIZlXVfVpyAAAAAXRSTlMAQObYZgAAAatJREFUSMe1lmtzgyAQRUVdRBSVQpua2jRJH///J5bbDTXpawYyPR/84rkzuyyIRXFB843iLxJ0vOy69hPv+8CvkSQdKtEQGMcp8PiodV17P89vbz8EkvSmIer7YSBSn3iPiHMPgS+BJL1plOp7CqyN1oG2RcDa58BZIFlHk0RxGcuS9brW2vvNxtqLgpJ0lMIy6yipqtbAZuPcNpCrn5dCtN8rRSRlXa8B7zG6j0CSzgPCIi4LSkApSpUldKKyrCroROM4zzk6N7osQkCLW6CqiJ6elJJSCOegj2OePo5EeM1a1LsOT+jWGsNHJUefZyxjFFeWBQ0LcTxeo0vJQypLFBCJoxJiv5+mfH2aoPPCncPDEuLmRsq7QJ5uDDaBc+uQoo4A9Hm+D+TrGIiUVRUjEBnow3AbSNf5eBijNQ4HR3j48YBcq3fddrt+klDOevQgE50aLdL1y3Kg83ii/vJizGlImTrK0ZoDl21CPm2vIk9HoG2N4YLWMtCmMbvdMHy7PZJ0DmiNgNbiA2uPx8Ph9MEortP5Gkarr6/O2cDhsAv8eWsn6P/wx/EO0u9IKWeIqzUAAAAASUVORK5CYII="
                                        : meso.amount < 100
                                        ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAwCAMAAABZu7juAAAAk1BMVEVMaXGqdwDuzCLMqhH/zADdqgDuuwDduwAAAAD/3QDMqgC7iADuzAC7mQuqiAp3VRHuuxHMmRG7iBH/7ljMmQBmShHdqhT//6qIZhSIYwAzIgBdRACZZgX/2CL/3TlVMwCZdydELQCZdwZ3RAz/7n13VQCqiCrMqiLMzLv/////7pnduyLuzDO7qne7u4i7u5m7qojgUgOkAAAAAXRSTlMAQObYZgAAAc9JREFUSMe11sFu00AUheHPsZ3YbqLSki6q7hCIHe//KgikIlWojZSC0qRO7NgOiwxRA6s6cBcjjy3/c3XuzLkTOYrMn7E5mg28KqJjcsdhvDTB96MV+tIznTOdAs+IPYtNDGQeDvx+9EwuUUstwqudznu3Gp/sfA78PvRM641GpQmqQCp249bEFb7Z9KbnOluNoQ2GaqSIFZYKN3a+2LyWnuwz7ww0oX6R2khlK9UqcX2C7vvMV2J0Ih98tZMrpSDWeucZD6+mZ1qFlZHCWiUystVpxCKpSKRVu8a8hzL7KOSWhsYWGBhrTc3UIjdWmj7KJJBbWVtgZx0+jDyZhfqWImfmPemwNbQVqcN8KTLxKFO58MOToaoXvQj8RPPCGjqPB99pxL2VmYfTOTU7cqzEQIelqVbTiz4ID1P3f/1fy1WHtfrQOwkidyjUgdDJLYP+rINir6VvZBbOdVKdWGOsligtg+pVf39P9sNWKUcp1qI81LNypVXgvpfPbGRKhdjqsFtexqWZ7oTTJOy3sRWiQNpn/tFMrvHY24H3nWlEyD896H1l662Vnyd0jz0/kWjFypD7hQtPhpif2Pl+d+0GqakSsXPc/ZOu/V9uHL8AAZujgSmwWXYAAAAASUVORK5CYII="
                                        : meso.amount < 1000
                                        ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA8CAMAAAAJ1/l9AAAAS1BMVEVMaXFEVmaIqqpEd3dVd3eZu7vM3d0AAABmiIirzcy7u1XOzHd3mZmZmTOZr6rd2oiIqpmqzLv///93mYiqu7vu///d7u53qpnMu2ITS3jOAAAAAXRSTlMAQObYZgAAAcZJREFUSMe9l+uagiAURdNUoJSRRPP9n3TY7hisvmbKg7P/0lqdwy06HP6MCjlsjxhvGoFChAPu+6bZqMiCI5sUIjzCWm9SZMDLsu+rkL7/WCHCAVtbhrSt1lBoXS55SyHAMdg0dYjWbUtB21JBCQT74Ep9hRCOZQO3Fho0ZO1eOGEGCiBa13dpmqLYA8ekRfi8xDnvMW2QeM8J3QdXapqMKcs1TgVaMKYNcQ7w8ZgTV0umCYvFrXpeZZ5PJ3ML4Ty4umUMmSbvuVUwPUkxz8CRhN++W4xfQsYRA4CBV5W1VRUVEWUeOhfiFGCxnGPZ8ZgAdyFQJLzrHhZNhGPagLN4/ZNqifcciYou5OG7BbhSGByGKGDpSaE1DwwXrFuyOixiHAfheoXgcuHBSPHeOWtxRRkzTdYSv+tcjEcBFFgoFIsrqq5RMgsHXBS5cQgwQfhIbMEY57CIuBjKEijh4/EJzoBzgdYtcBkhMWYch4Ewin+638Q4YP4QxRaiAldIglF8bpyHJm5RKrCNh4Fogl88CIT4YwtRQjTBv75jRDhbiApKiAJ+4yEmxNcKSlj2y1dEdvxeweI/fsGL8KR4e8oy4//07/EbiplO9UB/o9MAAAAASUVORK5CYII="
                                        : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA+CAMAAACsn+1dAAAAb1BMVEVMaXEzMyKZd1XMu6qId1W7u5lmZkQAAACZmXe7qoiqiFW7qpm7iWaIZkTdzLt3RBGqqohERCKqiGaKZhV3VUCZiHK7mRGIVReqfR+qmX7guwBYRDP82hD//4jMmVXdqhHEkTuqd1XdqlXdqgD/zAAdH0KVAAAAAXRSTlMAQObYZgAAAhZJREFUSIm1lotygjAQRStCHqaWFEQQUPv6/28s13VNwFodE84MhnG4Z8hmCby83EROuH1lPME4oFQ24gFJoEDKbMJiBEnmEyC+mPB+hgVpOqdgqkCwOwGBX8o5BbxwJOj7tu17moA403X/KIIFUKSpUkIsFt/f+/3xSEfbbgdYMa+AFFkmxH7/84No3x+P223buiIqNa+ASylE0yCIX8T0gBq400qRBCgkFChe37voQ/FgATYNLJbWQrh4mrr4nW0lgqCum6auIYAG8ab5+irLjw8cUh4OZflvLwYJpESgriEglMrzpilLHPmAlDzOIZDy7Q0tg3IJoU7kOQJ1jUMI3vBvTCJQQPHlkhdOXPDPIYE0vgDx5QBNQSmtqyo9Q41EkILjXlMFCjhuDAkwjSSpKnWBRDS6Yl6WM5LAmNXKGI4nibVK+Qp3BsVhIJaA4llmTHKC4qSwNkmKggrrTweP9qSEwQIoVn9izFjgFFc98JTAxUlQFK8exQBt6xTlDfaO4PCMAMuI8n1+ju+BmoseMR5JEVtgDP6k5aOpAK2NgdgJXBnjCqhQ6wGW+FirL8QXQEECNLMx1q4n7HZaXwu8TS2CwP/A3pzY7Xh0tz9u5ZgCeqWxAJPoOgg4rCZcxSMISMFYu9lUA9Mb/2MniCiglwRfVlXrtb+p+6+Wm584wQKWkIbjaTqO3v3MDRT4mmseiD4j+AWYJGZ7mn6PZgAAAABJRU5ErkJggg=="
                                    }
                                    style={{ height: "32px", width: "32px" }}
                                  />
                                  <br />
                                  <Typography
                                    variant="h8"
                                    fontWeight="normal"
                                    color="textPrimary"
                                  >
                                    {"평균 " +
                                      meso.amount.toLocaleString("ko-KR") +
                                      "메소"}
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {"평균 " +
                                    Number(meso.drops.in).toLocaleString(
                                      "ko-KR"
                                    ) +
                                    "마리 사냥 시 " +
                                    meso.drops.every +
                                    "개 드롭 (" +
                                    meso.dropChance +
                                    ")"}
                                </TableCell>
                              </TableRow>
                            ))}

                            {mob.meta.drops.items
                              .sort((a, b) =>
                                a.itemTypeInfo.engOverallCategory >
                                b.itemTypeInfo.engOverallCategory
                                  ? 1
                                  : b.itemTypeInfo.engOverallCategory >
                                    a.itemTypeInfo.engOverallCategory
                                  ? -1
                                  : 0
                              )
                              .map((item) => (
                                <TableRow key={item.itemId}>
                                  <TableCell
                                    sx={{ textAlign: "center" }}
                                    onClick={() =>
                                      this.props.search(item.korName)
                                    }
                                  >
                                    <a href="#">
                                      <img
                                        src={
                                          "https://maplestory.io/api/GMS/62/item/" +
                                          item.itemId +
                                          "/icon"
                                        }
                                        style={{ maxHeight: 50 }}
                                      />
                                      <br />
                                      <Typography
                                        variant="h8"
                                        fontWeight="bold"
                                        color="textPrimary"
                                        sx={{ fontSize: 12 }}
                                      >
                                        {item.korName}
                                        <br />
                                        {item.meta ? (
                                          <Chip
                                            deleteIcon={
                                              <img
                                                src="data:image/png;base64,R0lGODlhDAAPAMQXAN6qAO+6EP/vMf//ve/PAP/fEP/vjP/PMf/PAO/PjN66AP/fQt6qEM6qEO+6AP/fAO/PEP/vY86aAN66EL2KAP///wAAAP///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABcALAAAAAAMAA8AAAVX4CVaZCmeVlUNUWuhVTRNTzEZbyoTAVBABwppMGkEIBQIBDAZTpASSlTQtEQcEAdl+6CSIgrpNsqoJiYOQDTwWAgvFgMBoe02UVKJ/n2Cl0h9gYKDhIUhADs="
                                                alt="coin"
                                                style={{
                                                  height: "15px",
                                                  width: "15px",
                                                }}
                                              />
                                            }
                                            label={
                                              "재판매: " +
                                              item.meta.shop.price.toLocaleString(
                                                "ko-KR"
                                              )
                                            }
                                            color="default"
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                              height: "1.2rem",
                                              fontSize: "0.7rem",
                                              borderRadius: "4px",
                                              margin: "2px",
                                            }}
                                            onDelete={() => {}}
                                          ></Chip>
                                        ) : (
                                          ""
                                        )}
                                        <br />
                                        <Chip
                                          label={
                                            item.itemTypeInfo.korOverallCategory
                                          }
                                          color="default"
                                          variant="outlined"
                                          sx={{
                                            height: "1.2rem",
                                            fontSize: "0.7rem",
                                            borderRadius: "4px",
                                            margin: "2px",
                                          }}
                                        ></Chip>
                                        <Chip
                                          label={item.itemTypeInfo.korCategory}
                                          color="default"
                                          variant="outlined"
                                          sx={{
                                            height: "1.2rem",
                                            fontSize: "0.7rem",
                                            borderRadius: "4px",
                                            margin: "2px",
                                          }}
                                        ></Chip>
                                        <Chip
                                          label={
                                            item.itemTypeInfo.korSubCategory
                                          }
                                          color="default"
                                          variant="outlined"
                                          sx={{
                                            height: "1.2rem",
                                            fontSize: "0.7rem",
                                            borderRadius: "4px",
                                            margin: "2px",
                                          }}
                                        ></Chip>
                                      </Typography>
                                    </a>
                                  </TableCell>

                                  <TableCell sx={{ textAlign: "center" }}>
                                    {item.meta &&
                                      item.meta.equip &&
                                      Object.keys(item.meta.equip)
                                        .map((key) => key.includes("inc"))
                                        .includes(true) && (
                                        <Chip
                                          label={
                                            <React.Fragment>
                                              <div>
                                                {item.meta.equip.incSTR
                                                  ? "STR: +" +
                                                    item.meta.equip.incSTR +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incSTR
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incDEX
                                                  ? "DEX: +" +
                                                    item.meta.equip.incDEX +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incDEX
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incINT
                                                  ? "INT: +" +
                                                    item.meta.equip.incINT +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incINT
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incLUK
                                                  ? "LUK: +" +
                                                    item.meta.equip.incLUK +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incLUK
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incACC
                                                  ? "명중률: +" +
                                                    item.meta.equip.incACC +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incACC
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incEVA
                                                  ? "회피율: +" +
                                                    item.meta.equip.incEVA +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incEVA
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incPAD
                                                  ? "공격력: " +
                                                    item.meta.equip.incPAD +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incPAD
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incMAD
                                                  ? "마력: " +
                                                    item.meta.equip.incMAD +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incMAD
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incPDD
                                                  ? "물리방어력: " +
                                                    item.meta.equip.incPDD +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incPDD
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incMDD
                                                  ? "마법방어력: " +
                                                    item.meta.equip.incMDD +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incMDD
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incMHP
                                                  ? "체력: " +
                                                    item.meta.equip.incMHP +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incMHP
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incSpeed
                                                  ? "이동속도: " +
                                                    item.meta.equip.incSpeed +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incSpeed
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.incJump
                                                  ? "점프력: " +
                                                    item.meta.equip.incJump +
                                                    " " +
                                                    this.itemVariance(
                                                      item,
                                                      item.meta.equip.incJump
                                                    )
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.tuc
                                                  ? "업그레이드 가능 횟수: " +
                                                    item.meta.equip.tuc
                                                  : ""}
                                              </div>
                                            </React.Fragment>
                                          }
                                          color="primary"
                                          variant="outlined"
                                          sx={{
                                            height: "auto",
                                            width: "10rem",
                                            fontSize: "0.75rem",
                                            borderRadius: "4px",
                                            padding: "4px",
                                          }}
                                        ></Chip>
                                      )}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {item.meta &&
                                      item.meta.equip &&
                                      Object.keys(item.meta.equip)
                                        .map((key) => key.includes("req"))
                                        .includes(true) && (
                                        <Chip
                                          label={
                                            <React.Fragment>
                                              <div>
                                                {item.meta.equip.reqLevel
                                                  ? "레벨: " +
                                                    item.meta.equip.reqLevel
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.reqJob == 1
                                                  ? "직업: 전사"
                                                  : item.meta.equip.reqJob == 2
                                                  ? "직업: 마법사"
                                                  : item.meta.equip.reqJob == 3
                                                  ? "직업: 응애1"
                                                  : item.meta.equip.reqJob == 4
                                                  ? "직업: 궁수"
                                                  : item.meta.equip.reqJob == 8
                                                  ? "직업: 도적"
                                                  : item.meta.equip.reqJob == 9
                                                  ? "직업: 도적, 전사"
                                                  : item.meta.equip.reqJob == 13
                                                  ? "직업: 궁수, 도적, 전사"
                                                  : item.meta.equip.reqJob == 14
                                                  ? "직업: 응애12"
                                                  : item.meta.equip.reqJob == 15
                                                  ? "직업: 응애13"
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.reqPOP
                                                  ? "인기도: " +
                                                    item.meta.equip.reqPOP
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.reqSTR
                                                  ? "STR: " +
                                                    item.meta.equip.reqSTR
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.reqDEX
                                                  ? "DEX: " +
                                                    item.meta.equip.reqDEX
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.reqINT
                                                  ? "INT: " +
                                                    item.meta.equip.reqINT
                                                  : ""}
                                              </div>
                                              <div>
                                                {item.meta.equip.reqLUK
                                                  ? "LUK: " +
                                                    item.meta.equip.reqLUK
                                                  : ""}
                                              </div>
                                            </React.Fragment>
                                          }
                                          color="default"
                                          variant="outlined"
                                          sx={{
                                            height: "auto",
                                            width: "10rem",
                                            fontSize: "0.75rem",
                                            borderRadius: "4px",
                                            padding: "4px",
                                          }}
                                        ></Chip>
                                      )}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {"평균 " +
                                      Number(item.drops.in).toLocaleString(
                                        "ko-KR"
                                      ) +
                                      "마리 사냥 시 " +
                                      item.drops.every +
                                      "개 드롭 (" +
                                      item.dropChance +
                                      ")"}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
          {this.props.data.matchedItems.map((item) => (
            <div
              key={item.itemId}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px",
                color: "black",
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  width: "100%",
                  maxWidth: "75%",
                  margin: "auto",
                }}
              >
                <Table>
                  <TableHead sx={{ borderColor: "#FFFFFF" }}>
                    <TableRow>
                      <TableCell sx={{ width: 250 }}>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          아이템
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          color="textSecondary"
                        ></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Grid container direction="column" alignItems="center">
                          <Avatar
                            alt={item.korName}
                            sx={{
                              m: 1,
                              width: 150,
                              height: 150,
                              bgcolor: "#FAFAFA",
                            }}
                          >
                            <img
                              src={
                                "https://maplestory.io/api/gms/62/item/" +
                                item.itemId +
                                "/icon"
                              }
                              style={{ maxHeight: 100 }}
                              loading="lazy"
                            />
                          </Avatar>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="textPrimary"
                            sx={{ margin: 1 }}
                          >
                            {item.korName}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {item.description}
                          </Typography>
                          <div
                            style={{
                              marginTop: "8px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <Chip
                              deleteIcon={
                                <img
                                  src="data:image/png;base64,R0lGODlhDAAPAMQXAN6qAO+6EP/vMf//ve/PAP/fEP/vjP/PMf/PAO/PjN66AP/fQt6qEM6qEO+6AP/fAO/PEP/vY86aAN66EL2KAP///wAAAP///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABcALAAAAAAMAA8AAAVX4CVaZCmeVlUNUWuhVTRNTzEZbyoTAVBABwppMGkEIBQIBDAZTpASSlTQtEQcEAdl+6CSIgrpNsqoJiYOQDTwWAgvFgMBoe02UVKJ/n2Cl0h9gYKDhIUhADs="
                                  alt="coin"
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              }
                              label={
                                "재판매: " +
                                (item.meta &&
                                item.meta.shop &&
                                item.meta.shop.price
                                  ? item.meta.shop.price.toLocaleString("ko-KR")
                                  : "0")
                              }
                              color="default"
                              size="small"
                              variant="outlined"
                              sx={{
                                height: "1.2rem",
                                fontSize: "0.7rem",
                                borderRadius: "4px",
                                margin: "2px",
                              }}
                              onDelete={() => {}}
                            ></Chip>
                            {item.meta && item.meta.equip && (
                              <Chip
                                label={"필요 능력치"}
                                color="warning"
                                sx={{
                                  height: "1.5rem",
                                  fontSize: "0.75rem",
                                  borderRadius: "4px",
                                }}
                              ></Chip>
                            )}
                            {item.meta &&
                              item.meta.equip &&
                              Object.keys(item.meta.equip)
                                .map((key) => key.includes("req"))
                                .includes(true) && (
                                <Chip
                                  label={
                                    <React.Fragment>
                                      <div>
                                        {item.meta.equip.reqLevel
                                          ? "레벨: " + item.meta.equip.reqLevel
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.reqJob == 1
                                          ? "직업: 전사"
                                          : item.meta.equip.reqJob == 2
                                          ? "직업: 마법사"
                                          : item.meta.equip.reqJob == 3
                                          ? "직업: 응애1"
                                          : item.meta.equip.reqJob == 4
                                          ? "직업: 궁수"
                                          : item.meta.equip.reqJob == 8
                                          ? "직업: 도적"
                                          : item.meta.equip.reqJob == 9
                                          ? "직업: 도적, 전사"
                                          : item.meta.equip.reqJob == 13
                                          ? "직업: 궁수, 도적, 전사"
                                          : item.meta.equip.reqJob == 14
                                          ? "직업: 응애12"
                                          : item.meta.equip.reqJob == 15
                                          ? "직업: 응애13"
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.reqPOP
                                          ? "인기도: " + item.meta.equip.reqPOP
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.reqSTR
                                          ? "STR: " + item.meta.equip.reqSTR
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.reqDEX
                                          ? "DEX: " + item.meta.equip.reqDEX
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.reqINT
                                          ? "INT: " + item.meta.equip.reqINT
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.reqLUK
                                          ? "LUK: " + item.meta.equip.reqLUK
                                          : ""}
                                      </div>
                                    </React.Fragment>
                                  }
                                  color="warning"
                                  variant="outlined"
                                  sx={{
                                    height: "auto",
                                    width: "10rem",
                                    fontSize: "0.75rem",
                                    borderRadius: "4px",
                                    padding: "4px",
                                  }}
                                ></Chip>
                              )}
                            {item.meta && item.meta.equip && (
                              <Chip
                                label={"드롭 옵션"}
                                color="primary"
                                sx={{
                                  height: "1.5rem",
                                  fontSize: "0.75rem",
                                  borderRadius: "4px",
                                }}
                              ></Chip>
                            )}
                            {item.meta &&
                              item.meta.equip &&
                              Object.keys(item.meta.equip)
                                .map((key) => key.includes("inc"))
                                .includes(true) && (
                                <Chip
                                  label={
                                    <React.Fragment>
                                      <div>
                                        {item.meta.equip.incSTR
                                          ? "STR: +" +
                                            item.meta.equip.incSTR +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incSTR
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incDEX
                                          ? "DEX: +" +
                                            item.meta.equip.incDEX +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incDEX
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incINT
                                          ? "INT: +" +
                                            item.meta.equip.incINT +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incINT
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incLUK
                                          ? "LUK: +" +
                                            item.meta.equip.incLUK +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incLUK
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incACC
                                          ? "명중률: +" +
                                            item.meta.equip.incACC +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incACC
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incEVA
                                          ? "회피율: +" +
                                            item.meta.equip.incEVA +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incEVA
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incPAD
                                          ? "공격력: " +
                                            item.meta.equip.incPAD +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incPAD
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incMAD
                                          ? "마력: " +
                                            item.meta.equip.incMAD +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incMAD
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incPDD
                                          ? "물리방어력: " +
                                            item.meta.equip.incPDD +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incPDD
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incMDD
                                          ? "마법방어력: " +
                                            item.meta.equip.incMDD +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incMDD
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incMHP
                                          ? "체력: " +
                                            item.meta.equip.incMHP +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incMHP
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incSpeed
                                          ? "이동속도: " +
                                            item.meta.equip.incSpeed +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incSpeed
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.incJump
                                          ? "점프력: " +
                                            item.meta.equip.incJump +
                                            " " +
                                            this.itemVariance(
                                              item,
                                              item.meta.equip.incJump
                                            )
                                          : ""}
                                      </div>
                                      <div>
                                        {item.meta.equip.tuc
                                          ? "업그레이드 가능 횟수: " +
                                            item.meta.equip.tuc
                                          : ""}
                                      </div>
                                    </React.Fragment>
                                  }
                                  color="primary"
                                  variant="outlined"
                                  sx={{
                                    height: "auto",
                                    width: "10rem",
                                    fontSize: "0.75rem",
                                    borderRadius: "4px",
                                    padding: "4px",
                                  }}
                                ></Chip>
                              )}
                          </div>
                        </Grid>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ width: 200, textAlign: "center" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  드롭 몬스터
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{ textAlign: "center", width: "50%" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  정보
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  color="textSecondary"
                                >
                                  드롭율
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.mobs.map((mob) => (
                              <TableRow key={mob.mobId}>
                                <TableCell
                                  sx={{ textAlign: "center" }}
                                  onClick={() => this.props.search(mob.korName)}
                                >
                                  <a href="/#/">
                                    <img
                                      src={
                                        "https://maplestory.io/api/GMS/62/mob/" +
                                        mob.mobId +
                                        "/icon"
                                      }
                                      style={{ maxHeight: 50 }}
                                    />
                                    <br />
                                    <Typography
                                      variant="h8"
                                      fontWeight="bold"
                                      color="textPrimary"
                                    >
                                      {mob.korName}
                                      <br />
                                      <Chip
                                        label={"레벨: " + mob.meta.level}
                                        color="default"
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          height: "1.2rem",
                                          fontSize: "0.7rem",
                                          borderRadius: "4px",
                                          margin: "2px",
                                        }}
                                      ></Chip>
                                    </Typography>
                                  </a>
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label={
                                      "HP " +
                                      mob.meta.maxHP.toLocaleString("ko-KR")
                                    }
                                    color="error"
                                    sx={{
                                      height: "1.5rem",
                                      width: "10rem",
                                      fontSize: "0.75rem",
                                      borderRadius: "4px",
                                    }}
                                  ></Chip>
                                  <Chip
                                    label={
                                      "MP " +
                                      mob.meta.maxMP.toLocaleString("ko-KR")
                                    }
                                    color="primary"
                                    sx={{
                                      height: "1.5rem",
                                      width: "10rem",
                                      fontSize: "0.75rem",
                                      borderRadius: "4px",
                                    }}
                                  ></Chip>
                                  <Chip
                                    label={
                                      "EXP " +
                                      mob.meta.exp.toLocaleString("ko-KR")
                                    }
                                    color="warning"
                                    sx={{
                                      height: "1.5rem",
                                      width: "10rem",
                                      fontSize: "0.75rem",
                                      borderRadius: "4px",
                                    }}
                                  ></Chip>
                                  <Chip
                                    label={
                                      "물리방어력 " +
                                      mob.meta.physicalDefense.toLocaleString(
                                        "ko-KR"
                                      )
                                    }
                                    color="default"
                                    sx={{
                                      height: "1.5rem",
                                      width: "10rem",
                                      fontSize: "0.75rem",
                                      borderRadius: "4px",
                                    }}
                                  ></Chip>
                                  <Chip
                                    label={
                                      "마법방어력 " +
                                      mob.meta.magicDefense.toLocaleString(
                                        "ko-KR"
                                      )
                                    }
                                    color="default"
                                    sx={{
                                      height: "1.5rem",
                                      width: "10rem",
                                      fontSize: "0.75rem",
                                      borderRadius: "4px",
                                    }}
                                  ></Chip>
                                  <Chip
                                    label={
                                      "회피율 " +
                                      mob.meta.evasion.toLocaleString("ko-KR")
                                    }
                                    color="default"
                                    sx={{
                                      height: "1.5rem",
                                      width: "10rem",
                                      fontSize: "0.75rem",
                                      borderRadius: "4px",
                                    }}
                                  ></Chip>
                                </TableCell>
                                <TableCell>
                                  {"평균 " +
                                    Number(mob.drops.in).toLocaleString(
                                      "ko-KR"
                                    ) +
                                    "마리 사냥 시 " +
                                    mob.drops.every +
                                    "개 드롭 (" +
                                    mob.dropChance +
                                    ")"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

export default Result;

