import React from "react";

import Options from "./Options";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      options: [],
    };
  }

  find = (event) => {
    const keyword = event.target.value.replace(/ /g, "");
    this.setState({ keyword: keyword });
    if (keyword) {
      this.setState({
        options: Options.filter((option) => {
          if (
            option.label.includes(keyword) ||
            option.label.replace(/ /g, "").includes(keyword) ||
            option.extra.includes(keyword)
          ) {
            return true;
          }
        }).slice(0, 10),
      });
    }
  };

  search = (event) => {
    event.preventDefault();
    this.props.search(this.state.keyword);
  };

  render() {
    return (
      <React.Fragment>
        <Autocomplete
          sx={{
            display: "inline-block",
            width: "50%",
          }}
          ref={(input) => {
            this.autoCompleteInput = input;
          }}
          options={this.state.options}
          onChange={(event, value) => {
            if (value) this.props.search(value.label);
          }}
          noOptionsText={"일치하는 검색 결과가 없습니다."}
          filterOptions={(options, state) =>
            options.filter(({ label, extra }) => {
              return (
                label.includes(state.inputValue) ||
                label.replace(/ /g, "").includes(state.inputValue) ||
                extra.includes(state.inputValue)
              );
            })
          }
          renderOption={(props, option, state) => (
            <li {...props}>
              {option.type == "mob" ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={
                    "https://maplestory.io/api/gms/62/mob/" +
                    option.id +
                    "/icon"
                  }
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={
                    "https://maplestory.io/api/gms/62/item/" +
                    option.id +
                    "/icon"
                  }
                />
              )}
              &nbsp;&nbsp;&nbsp;
              <Chip
                label={option.type == "mob" ? "몬스터" : "아이템"}
                color={option.type == "mob" ? "warning" : "primary"}
                size="small"
                variant="outlined"
                sx={{
                  height: "1.2rem",
                  fontSize: "0.7rem",
                  borderRadius: "4px",
                  margin: "2px",
                }}
              />
              &nbsp;&nbsp;&nbsp;{option.label}
            </li>
          )}
          renderInput={(params) => (
            <form ref={params.InputProps.ref} onSubmit={this.search}>
              <TextField
                {...params.inputProps}
                sx={{ width: "100%" }}
                color="warning"
                placeholder="검색할 내용을 입력해주세요."
                onKeyUp={this.find}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          )}
        />
      </React.Fragment>
    );
  }
}

export default SearchBar;

